
.cg-busy{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1001;
}

.cg-busy-animation.ng-hide-add,
.cg-busy-animation.ng-hide-remove {

    transition: all .3s ease;
    display: block !important;
}
.cg-busy-animation.ng-hide-remove {
    opacity: 0;

    transform: translate(0px,-40px);
}
.cg-busy-animation.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;

    transform: translate(0px,0px);
}
.cg-busy-animation.ng-hide-add {
    opacity: 1;

    transform:translate(0px,0px);
}
.cg-busy-animation.ng-hide-add.ng-hide-add-active {
    opacity: 0;

    transform:translate(0px,-40px);
}

.cg-busy-backdrop {
    background-color: rgba(0,0,0,.6);
    opacity: 1;
}

.cg-busy-backdrop-animation.ng-hide-add,
.cg-busy-backdrop-animation.ng-hide-remove {
    transition:opacity .3s ease;
    display: block !important;
}

.cg-busy-backdrop-animation.ng-hide {
  opacity: 0;
}

/* All styles below are for the default template. */

.cg-busy-default-wrapper {
    text-align: center;
    position: fixed !important;
    left: calc(50% - 24px) !important;
    right: !important;
}

.cg-busy-default-sign {
    display: inline-block;
    position: fixed;
    z-index: 14;
    padding: 10px;
    color: $black;
    border-radius: 1px;
    background: none;
    max-width: 300px;
    font-size: 20px;
    line-height: 22px;
    top: 42%;
    left: 45%;
    top: calc(50% - 28px);
    left: calc(50% - 28px);

    @media (min-width: $screen-sm-min) {
        max-width: 460px;
    }
}

.cg-busy-default-text {
    margin: 6px 0 0 44px;

    &:empty {
		margin: 0;
    }

    font-size: 20px;
    color: $spinner-message-color;
    text-align: left;
    max-width: 400px;
    line-height: 22px;
}
.cg-busy-default-spinner{
    width: 35px;
    height: 35px;
    display: inline-block;
    top: 12px;
}
.cg-busy-default-spinner div {
    width: 3px;
    height: 9px;
    background: $spinner-backdrop-color;
    position: absolute;
    left: 26px;
    top: 23px;
    opacity: 0;
    animation:cg-busy-spinner-anim 1s linear infinite;
    border-radius: 1px;
    box-shadow: 0 0 1px rgba(0,0,0,.1);
}
.cg-busy-default-spinner div.bar1{
    transform: rotate(0deg) translate(0, -142%);
    animation-delay :0s;
}
.cg-busy-default-spinner div.bar2{
    transform: rotate(30deg) translate(0, -142%);
    animation-delay: -0.9167s;
}
.cg-busy-default-spinner div.bar3{
    transform: rotate(60deg) translate(0, -142%);
    animation-delay: -0.833s;
}
.cg-busy-default-spinner div.bar4{
    transform: rotate(90deg) translate(0, -142%);
    animation-delay: -0.75s;
}
.cg-busy-default-spinner div.bar5{
    transform: rotate(120deg) translate(0, -142%);
    animation-delay: -0.667s;
}
.cg-busy-default-spinner div.bar6{
    transform: rotate(150deg) translate(0, -142%);
    animation-delay: -0.5833s;
}
.cg-busy-default-spinner div.bar7{
    transform: rotate(180deg) translate(0, -142%);
    animation-delay: -0.5s;
}
.cg-busy-default-spinner div.bar8{
    transform: rotate(210deg) translate(0, -142%);
    animation-delay: -0.41667s;
}
.cg-busy-default-spinner div.bar9{
    transform: rotate(240deg) translate(0, -142%);
    animation-delay: -0.333s;
}
.cg-busy-default-spinner div.bar10{
    transform: rotate(270deg) translate(0, -142%);
    animation-delay: -0.25s;
}
.cg-busy-default-spinner div.bar11{
    transform: rotate(300deg) translate(0, -142%);
    animation-delay: -0.1667s;
}
.cg-busy-default-spinner div.bar12{
    transform: rotate(330deg) translate(0, -142%);
    animation-delay: -0.0833s;
}

@keyframes cg-busy-spinner-anim {
    from {opacity: 1;}
    to {opacity: 0.25;}
}

.cg-busy-transparent .cg-busy-default-sign{
    background: none;
}
