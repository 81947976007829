.ocf-step-indicator {
	&:before {
		content: attr(step-indicator-txt);
		white-space: pre;
		color: $abn-contrast;
		background-color: $abn-additional;
		height: 26px;
		width: auto;
		padding: 0 10px 0 10px;
		margin-right: 10px;
		border-radius: 2px;
	}
}
