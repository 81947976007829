/**
 * ABN AMRO Alerts
 * Styling of alerts
 * Bootstrap overrides
 */

// alert-warning

.alert {
    border: none;
    @include clearfix();
    margin: 0 (-$grid-gutter-width / 2) $grid-gutter-width (-$grid-gutter-width / 2);
    padding-right: ($grid-gutter-width/2);
    padding-left: ($grid-gutter-width/2);

    @media (min-width: $screen-sm-min) {
        margin-left: -($grid-gutter-width / 1);
        margin-right: -($grid-gutter-width / 1);
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
    }
}

.alert-warning {

    .glyphicon {
        @include square($icon-size-default);
     }

    .glyphicon ~ h3,
    .glyphicon ~ p {
        margin-left: ($icon-size-default + ($grid-gutter-width/2));

        @media (min-width: $screen-sm-min) {
            margin-left: ($icon-size-default + ($grid-gutter-width));
        }
    }

    .ocf-icon-close {
        border-radius: 20px;
        margin-left: 50px;
    }

    h3 {
        color: $gray-darkest;
        margin-top: 0;
        margin-bottom: 3px;
    }

    // alerts can have buttons in them
    .btn-primary {
        margin-top: 10px;
        float: right;
    }

    .collapse,
    .collapsing {
        border-bottom: 1px solid $gray-lighter;
        margin: -5px 0 15px 0;
        padding-bottom: 10px;
    }
}

.alert-info {
    background: $abn-contrast;
    padding: $grid-gutter-width ($grid-gutter-width/2);

   .glyphicon {
        float: left;
    }

    .glyphicon ~ h3,
    .glyphicon ~ p {
        margin-left: ($icon-size-medium + ($grid-gutter-width/2));
        color: $gray-darkest;

        @media (min-width: $screen-sm-min) {
            margin-left: ($icon-size-medium + ($grid-gutter-width));
        }
    }

    @media (min-width: $screen-sm-min) {
        margin: $grid-gutter-width 0 $grid-gutter-width 0;
		padding: $grid-gutter-width;
	}

}