// Enhancing Password fields to create a better UX by overlaying custom password indicators

.ocf-pin {
    height: 36px;
    position: relative;

    input {
        background-color: transparent;
        color: transparent;
        height: 34px;
        overflow: hidden;
        text-indent: -999px;

        &.form-control {
            border: none;
        }

        &:focus {
            transform: scale(0,0);
            text-indent: -999px;
        }

    }

    &[disabled] {
        .ocf-pin-boxforinput {
            display: none;
            color: transparent;
        }
    }
    &.ocf-pin-focus {
        background: $brand-warning;

    }
}

// surrounding boxes
.ocf-pin-boxforbox,
.ocf-pin-boxforinput {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    height: 34px;
    padding: 0 0;
    text-align: center;
    width: 100%;
}


// parent box for input
.ocf-pin-boxforinput {
    background-color: transparent;
}


// grandparent box for ocf-pin-status-box
.ocf-pin-enclosure {
    display: inline-block;
    height: 34px;
    width: auto;
}

// ocf-pin-status-box
.ocf-pin-status-box {
    display: inline-block;
    position: relative;
    float: left;
    top: 0;
    height: 30px;
    width: 20px;
    margin: 0;
}

.ocf-pin-status-box.ocf-pin-active {
    background-image: svg("ocf-icon-pin-active", ".primaryFill[fill]: #{$gray-darkest};");
}

// cursor when entering value

.ocf-pin .ocf-pin-status-box.ocf-pin-state {

    &::after {
    content: '\007c';
    display: inline-block;
    height: 22px;
    padding-left: 15px;
    width: 1px;
    font-size: 22px;
    color: $gray-darkest;
    animation: blink 1s infinite;
    }
}

.ocf-pin-state-default .ocf-pin-status-box:nth-child(1) {

    &::before {
        content: '\007c';
        display: inline-block;
        height: 22px;
        padding-right: 15px;
        width: 1px;
        font-size: 22px;
        color: $gray-darkest;
        animation: blink 1s infinite;
    }
}


// background dots
.ocf-pin  {

    .active {
        background-image: svg("ocf-icon-pin-active", ".primaryFill[fill]: #{$gray-darkest};");
    }

    .inactive {
        background-image: svg("ocf-icon-pin-inactive", ".primaryFill[fill]: #{$gray};");
    }

}

.ocf-pin-default {
    //text-align: center;
    letter-spacing: 10px;
}
input.ocf-pin-default[readonly],
.ocf-pin-boxforinput input[readonly] {
        cursor: text;
        background-color: transparent
}



// Validation backgrounds need to be set off for the input fields

.has-warning,
.has-error {
    .ocf-pin input {
        background-color: transparent!important;
    }
}


// feedback icon inside pin
.form-horizontal .has-warning,
.form-horizontal .has-error {

    .ocf-pin + .form-control-feedback {
        right: 52%;
        right: calc( 50% + 8px);
    }

    .ocf-pin .form-control {
        padding-right: 0px;
    }
}

.ocf-pin + .help-block {
    margin-top: 3px;
}