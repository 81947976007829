﻿//
// CSS Animations Library
// --------------------------------------------------


// animate from left to right

@keyframes SwipeLeftToRight {
    0% {
		transform: translateX(-100%);
	}
    100% {
		transform: translateX(0%);
	}
}


@keyframes SwipeRightToLeft {
    0% {
		transform: translateX(100%);
	}
    100% {
		transform: translateX(0%);
	}
}

@keyframes fadeIn {
    0% {
		opacity: 0;
	}
    100% {
		opacity: 1;
	}
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes reveal-line-left-to-right {
    0% {
        stroke-dashoffset: 55;
        stroke-dasharray: 50; 
        transform: translate(20px, 5px);
        
    }
    27% {
        stroke-dashoffset: 55;
        stroke-dasharray: 50;
        transform: translate(20px, 5px);
    }
    39% {
        stroke-dashoffset: 0;
        stroke-dasharray: 50;
        transform: translate(20px, 5px);
    }
    55% {
        stroke-dashoffset: 0;
        stroke-dasharray: 50;
        transform: translate(20px, 5px);
    }
    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 50;
        transform: translate(20px, 125px);
    }
}

@keyframes reveal-and-sink {
    0% {
        stroke-dashoffset: 150;
        stroke-dasharray: 150;
        transform: translate(20px, 5px);
    }
    5% {
        stroke-dashoffset: 150;
        stroke-dasharray: 150;
        transform: translate(20px, 5px);

    }
    28% {
        stroke-dashoffset: 0;
        stroke-dasharray: 150;
        transform: translate(20px, 5px);
    }
    55% {
        stroke-dashoffset: 0;
        stroke-dasharray: 150;
        transform: translate(20px, 5px);
    }
    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 150;
        transform: translate(20px, 125px);
    }
}

@keyframes skew-open {
    0% {
        transform: skew(0deg) scale(1,1);
    }
    35% {
        transform: skew(0deg) scale(1,1);
    }
    55% {
        transform: skew(-7deg) scale(1,0.9);
    }
    75% {
        transform: skew(-7deg) scale(1,0.9);
    }
    100% {
        transform: skew(0deg) scale(1,1);
    }
}

.doc,
.line,
.folder,
.folder-open {
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.doc {
    fill: none;
    stroke: $icon-success;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: reveal-and-sink 2804ms infinite ease-in-out;
}

.line {
    stroke: #888;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: reveal-line-left-to-right 2804ms infinite ease-in-out;
}

.folder,
.folder-open {
    fill: #f9f9f9;
    stroke: $icon-success;
}

.folder-open {
    fill: #e0e0e0;
    transform-origin: 100% 100%;
    animation: skew-open 2804ms infinite ease-in-out;
}