//
// Navbars
// --------------------------------------------------
/**
* KNOWN ISSUE WITH MENU (ON MOBILE):
* [ISSUE] menu reinitiates the sliding transition between resizing window from 425px width to 265px width.
* This issue happens because of the transition (the right-property changes on change in viewport which triggers the transition).
* It has been agreed to find this issue acceptable and leave it as it is.
*/

// Extensions
.navbar-container {
    z-index: 105;

    @media (max-width: $screen-md - 1px) {
        width: calc(#{$menu-width-xs} + 55px);
        right: 100%;
        right: calc(100% - 55px);
        position: absolute;
        height: 55px;
    }

    @media (max-width: $screen-xs - 1px) {
        width: calc(#{$menu-width-xxs} + 55px);
        right: 100%;
        right: calc(100% - 55px);
        position: absolute;
    }
}

.navbar {
    border: none;

    &:before,
    &:after {
        content: '';
        display: none;
    }
}

.navbar-main {
    height: 36px;

    ul.navbar-nav {
        @media (min-width: $screen-md) {

            background-color: $abn-action;
            margin-left: 77px;

            li.active > a:after {
                background-color: $abn-accent;
                border-right: ($grid-gutter-width / 2) solid $abn-action;
                border-left: ($grid-gutter-width / 2) solid $abn-action;
                bottom: 0px;
                content: "";
                height: 6px;
                position: absolute;
                right: 0px;
                width: 100%;
            }

            // hide nested nav for screens > md
            ul.nav {
                display: none;
            }

            > li > a {
                padding: ($grid-gutter-width / 2);
                margin: 0;
                text-decoration: none;
            }

            > li {
                line-height: 16px;
            }

            > li > a {
                height: 36px;
                padding: 6px 10px 0 10px;
                color: $abn-contrast;

                &:hover {
                    background-color: $abn-primary-dark;
                }
            }
        }
    }
}
