.input-group-addon.input-group-addon-currency {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
}

.input-group-addon-currency{
    .pre-scrollable{
        max-height: 382px;
    }
}