// Response code

.ocf-response-code {

    .glyphicon {
        float: left;
        margin-right: 10px;
    }

    p {
        @extend .ocf-font-large;
        line-height: 1;
        margin-bottom: 0;
    }
}