//
// Navbars vertical
// --------------------------------------------------
/**
* KNOWN ISSUE WITH MENU (ON MOBILE):
* this is an extension to style a vertical navbar with children
* the style can be applied to the navbar-main on mobile view.
*/

.navbar-vertical .navbar-nav {
    min-height: 36px;
    height: 100%;
    margin-right: 0;

    li {
        border-bottom: 1px solid $gray-light;
        float: none;

        li:first-child {
            border-top: 1px solid $gray-light;
        }

        li:last-child {
            border-bottom-width: 0;
        }

        a {
            font-size: $font-size-medium;
            margin: 0;
            min-height: 55px;
            color: $abn-action;
            padding: 16px 15px 15px 15px;
            text-decoration: none;
            background-color: $abn-contrast;

            &:focus,
            &:hover {
                color: $abn-action;
                background-color: $ocf-gray-menu-lighter;
            }
        }

        // when the href matches the current page
        &.active {
            font-weight: bold;

            li {
                font-weight: normal;
            }

            > a {
                &,
                &:hover,
                &:focus {
                    color: $abn-action;
                    background-color: $abn-contrast;
                }
            }
        }

        &.ocf-has-subnav {

            // hide nested children by default
            ul.nav {
                display: none;
            }

            // when expanded, show direct children
            &.expanded > ul.nav {
                display: block;
            }

            // create an element to open a submenu
            &:before {
                background-image: svg("ocf-icon-chevron-down", ".primaryFill[fill]: #{$icon-additional};");
                background-position: 13px 5px;
                background-repeat: no-repeat;
                border-left: 1px solid $gray;
                content: '';
                cursor: pointer;
                height: 35px;
                position: absolute;
                right: 0;
                top: 10px;
                width: 55px;
                z-index: 10;
                font-weight: normal;
            }

            // show expanded state
            &.expanded {
                &:before {
                    background-image: svg("ocf-icon-chevron-up", ".primaryFill[fill]: #{$icon-additional};");
                }
            }
         }

         // Level 2
        &.ocf-subnav-level2 a {
            padding-left: 25px;
            background-color: $ocf-gray-branding;

            &:hover,
            &:focus {
                background-color: $ocf-gray-menu-light;
            }
        }

        // Level 3
        &.ocf-subnav-level3 a {
            padding-left: 35px;
            background-color: $ocf-gray-menu-dark;

            &:hover,
            &:focus {
                background-color: $ocf-gray-menu-darker;
            }
        }

        // level 4
        &.ocf-subnav-level4 a {
            padding-left: 45px;
            background-color: #c9c9c9;

            &:hover,
            &:focus {
                background-color: #AEAEAE;
            }
        }
    }

    // toplevel on mobile active state gives specific styling
    > li.active {
        > a {
            &,
            &:hover,
            &:focus {
                @media (max-width: $screen-md - 1) {
                    border-left: 5px solid $abn-accent;
                    padding-left: 10px;
                }
            }
        }
    }
}

// override default bootstrap for nav-vertical
@media (min-width: $screen-md) {
    .navbar-vertical,
    .navbar-vertical .navbar-nav    {
        background: $abn-contrast;
        float: none;
    }
}
