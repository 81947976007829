@font-face {
    font-family: 'Roboto Condensed';
    src: url('../../../fonts/roboto-condensed-regular.woff2') format('woff2'),
         url('../../../fonts/roboto-condensed-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/roboto-regular.woff2') format('woff2'),
         url('../../../fonts/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/roboto-bold.woff2') format('woff2'),
         url('../../../fonts/roboto-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

/* override to let the colour of warning text differ from the labels with validation states in form-groups

*/


@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);



//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h2,
.h2 {
    color: $heading2-color;
    font-size: $font-size-h2;

    @media (max-width: $screen-xs - 1) {
        font-size: $ocf-panel-title-small;
    }
}

h3,
.h3 {
  color: $heading3-color;
};

h4,
.h4 {
  color: $heading4-color;
  font-size: $font-size-h4;
};

h5,
.h5 {
  color: $heading5-color;
  font-family: $font-family-sans-serif;
  
};

h6,
.h6 {
  color: $heading6-color;
  font-size: $font-size-small;
  font-family: $font-family-sans-serif;
  font-weight: bold;
};

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {

  small,
  .small {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
  }
}

// override the color of the heading elements for tile title
//Could go in the object it self but it seems that the compiler wont compile it correctly (fix story is created)
.ocf-tile-title {
  color: $gray-darkest;
}

// override to use h2 for SEO reasons, with default text bold
.ocf-seo-title {
    color: $paragraph-color;
    font-size: $font-size-base;
    font-family: $font-family-sans-serif;
    font-weight: bold;

    @media (max-width: $screen-xs - 1) {
        font-size: $font-size-base;
    }
}

// Lists
//  -------------------------

dt {
  font-weight: normal;
  color: $gray;
}

.dl-horizontal {
  dd {
    @include clearfix(); // Clear the floated `dt` if an empty `dd` is present
  }

  @media (min-width: $screen-sm) {
    dt {
      float: left;
      width: ($dl-horizontal-offset - 20);
      clear: left;
      text-align: right;
      @include text-overflow();
    }
    dd {
      margin-left: $dl-horizontal-offset;
    }
  }
}


// Custom "bulleted" lists
ul.ocf-list {
    list-style-type: none;
    padding-left: 0px;

    li {
        background-position: -5px 4px;
        background-repeat: no-repeat;
        background-size: 16px 16px;

        @media (max-width: $screen-sm - 1) {
          margin-bottom: 8px;
        }

        .glyphicon {
            @include square($icon-size-small);

            margin-top: 3px;
        }

      &:last-child {
        margin-bottom: 0px;
        @media (max-width: $screen-sm - 1) {
          margin-bottom: 0px;
        }
      }
    }
}

ol.ocf-list,
dl.ocf-list {
  li,
  dd {
    .glyphicon {
        @include square($icon-size-small);

        margin-top: 3px;
    }
  }
}


// Bulleted list
ul.ocf-bulleted-list {

    li {
        background-image: svg("ocf-icon-bulleted-list-dot", ".primaryFill[fill]: #{$gray-darkest};");
        padding-left: 14px;
    }

    ul {
        list-style-type: none;
        padding-left: 20px;

        @media (max-width: $screen-sm - 1) {
            margin-top: 10px;
        }

        li {
            background-image: svg("ocf-icon-bulleted-list-disk", ".primaryFill[fill]: #{$gray-darkest};");
            padding-left: 14px;

            &:first-child {
              @media (max-width: $screen-sm - 1) {
                margin-top: 7px;
              }

            }

        }
    }
}


// Numeric bulleted list
ol.ocf-list {
  padding-left: 22px;
}


// Feature list
ul.ocf-featurelist {

    li {
        background-image: svg("ocf-icon-checkbox-check", ".primaryFill[fill]: #{$brand-success};");
        background-position: 0 4px;
        padding-left: 24px;
    }
}

// Linklist
ul.ocf-linklist {

    li a {
        background-image: svg("ocf-icon-link-arrow", ".primaryFill[fill]: #{$brand-primary};");
        background-position: 0 4px;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        display: list-item;
        padding-left: 24px;

        &:hover,
        &:focus,
        &:active {
            background-image: svg("ocf-icon-link-arrow", ".primaryFill[fill]: #{$abn-attention};");
        }
    }
}


// Inline text elements
//  -------------------------

small,
.small {
  font-size: $font-size-small;
}

.ocf-font-smallest {
  font-size: $font-size-smallest;
}

.ocf-font-large {
  font-size: $font-size-large;
}

// remove kringeltje from links
a:focus {
    outline: none;
}

// positive & negative

.ocf-positive {
    color: $ocf-positive;
}

.ocf-negative {
    color: $ocf-negative;
}

.ocf-optional {
    color: $gray;
}