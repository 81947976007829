/**
 * ABN AMRO Totals
 * Styling of amount totals with a short summary
 * Bootstrap overrides
 */

.ocf-totals {
    background: $gray-dark;
    color: $abn-contrast;
    margin: 20px -10px;

    h5 {
        color: $abn-contrast;
    }

    .ocf-totals-header {
        @extend .clearfix;
        background: $black;
        padding: 15px 10px;

        .ocf-totals-description {
            @extend .pull-left;
        }

        .ocf-totals-amount {
            @extend .pull-right;
        }
    }

    .ocf-totals-details {
        @extend .clearfix;
        border-top: 1px solid $abn-contrast;
        padding: 20px 10px 0;

        .row {
            padding-bottom: 20px;
        }

        .ocf-totals-amount {
            @media (min-width: $screen-xs) {
                text-align: right;
            }
        }
    }
}
