/**
 * ABN AMRO Alerts
 * Styling of bill
 * Bootstrap overrides
 */

.ocf-bill {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;

    .ocf-i-text.top.in {
        padding-right: 0;
        padding-left: 0;
    }

    .ocf-bill-input {
        width: 100%;
        background-color: darken($gray-lighter, 2%);
        padding: ($grid-gutter-width / 2);

        @media (min-width: $screen-sm-min) {
            width: 50%;
            order: 1;
        }

        .row {
            margin: 0;
            margin-bottom: 10px;
        }

        legend {
            float: left;
            width: auto;

        }

        button {
            margin-left: 5px;
        }
    }


    .ocf-bill-input-options {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 50%;
            order: 3;
        }
    }

    .ocf-bill-result {
        background: $abn-contrast;
        width: 100%;
        padding: 10px;

        @media (min-width: $screen-sm-min) {
            width: calc( 50% - #{($grid-gutter-width)});
            margin-left: ($grid-gutter-width);
            order: 2;
        }

        .ocf-bill-result-value {
            text-align: right;
        }

        &::before {
            content:' ';
            width: 0;
            height: 0;
            border-top: 50px solid $gray-lighter;
            border-left: 50px solid $gray-light;
            z-index: 20;
            float: right;
            position: relative;
            right: -($grid-gutter-width / 2);
            top: -($grid-gutter-width / 2);
        }
    }

    .ocf-bill-result-options {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: right;
        width: 100%;

        order: 4;


        @media (min-width: $screen-sm-min) {
            width: 50%;
        }
        .btn {
            display: inline-block;
        }
    }

    fieldset {
        margin: 0 0px;
        padding: 0 0;
        background-color: transparent;
    }

    legend {
        margin: 0 0;
        padding: 0 0;
    }

}

.ocf-bill-column {
    background-color: $abn-contrast;
    //height: auto;
    min-height: 1px;
    padding: 0 0;
    position: relative;

    &:nth-of-type(2) {
        background-color: $gray-light;
    }
}

.ocf-bill-buttons {
    background-color: $gray-lighter;
    bottom: 0;
    display: block;
    margin-top: 20px;
    min-height: 1px;
    padding-right: 20px;
    //padding-bottom: 20px;
    position: absolute;
    text-align: right;
}

.ocf-bill-buttons {
    //border: 1px solid red;
    //bottom: 0;
    margin-top: 20px;
    //position: absolute;
}

.ocf-bill-summary {
    background: $brand-success;

    margin: $grid-gutter-width (-($grid-gutter-width / 2)) $grid-gutter-width (-($grid-gutter-width / 2));
    padding: $grid-gutter-width  ($grid-gutter-width / 2) ;


    .row {
        margin: 0;
        //padding-left: 20px;
    }

    h5,
    label,
    span,
    div {
        color: $abn-contrast;
    }

    h5 {
        margin: 0;
    }

    label {
        margin: 0;
    }
}
