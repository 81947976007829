//  compare is a structure to compare several items or products

.ocf-compare {

    .ocf-compare-item {
        background: $abn-contrast;
        text-align: center;
        border: 0;
        box-shadow: 0 0 7px rgba($black, .2);

        @media (min-width: $screen-sm) {
            border: 1px solid $gray-light;
            box-shadow: none;

        }

        @media (max-width: $screen-sm - 1px) {

            &:hover {
                cursor: pointer;
            }

            &.ocf-selected {
                border: 1px solid $brand-success;
            }
        }

        .ocf-seo-title {
            margin: 0 0 10px 0;
        }

        &[promotional-text] {
            margin-top: 23px;

            @media (max-width: $screen-sm - 1) {
                margin-bottom: 23px;
            }

            &:before {
                display: block;
                background: $brand-success;
                color: $abn-contrast;
                border-radius: 4px 4px 0 0;
                content: attr(promotional-text);
                margin-top: -32px;
                padding: 5px 0;
            }
        }

        &[promotional-text=""] {
            margin-top: 23px;

            @media (max-width: $screen-sm - 1) {
                margin-bottom: 0;
            }

            &:before {
                display: none;
                background: $brand-success;
                color: $abn-contrast;
                border-radius: 4px 4px 0 0;
                content: "";
                padding: 5px 0;
            }
        }
    }

    .ocf-compare-detail,
    .ocf-compare-head {
        margin: 0 10px;
        padding-top: 20px;

        @media (min-width: $screen-xxs) {
            margin: 0 15px;
        }
    }

    .ocf-compare-head {
        text-align: left;

        @media (min-width: $screen-sm) {
            text-align: center;
        }

        .img-responsive {
            margin-bottom: 10px;
        }

        @media (max-width: $screen-sm - 1) {
            img {
                max-width: 75px;
                max-height: 75px;
                float: left;
                margin: 0 10px 10px 0;
            }

            .ocf-list {
                float: left;
                @media (max-width: $screen-xs - 1px) {
                    min-width: 100%;
                }
            }
        }
    }

    .btn.btn-primary {
        float: none;
    }

    .ocf-compare-detail {
        @include clearfix();
        border: 1px solid $gray-light;
        border-width: 0 0 1px 0;

        .glyphicon {
            @media (min-width: $screen-sm) {
                @include square($icon-size-medium);
            }
        }

        ul {
            margin-bottom: 20px;
            @media (min-width: $screen-sm) {
                li{
                    &:last-child {
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }

    .ocf-compare-detail-show {
        marging-top: -20px;
    }

    .ocf-compare-details-all {
        margin-bottom: 20px;
        padding: 0;

        .ocf-compare-detail {

            &:last-child {
                border-width: 0;
            }
        }
    }

    .ocf-compare-detail-wrapper {
        .ocf-compare-detail {
            &:last-child {
                border-width: 0;
            }
        }
    }

    .ocf-link {
        padding:6px 10px;
    }
}
