.ocf-dropdown {
    span.glyphicon {
        margin-right: 2px;
    }
    span.caret {
        margin-left: 4px;
    }
    @media (max-width: $screen-xs-min) {
        span.caret {
            margin-left: 0;
        }
    }
}

.dropdown-menu {
    @include box-shadow($box-shadow);

    //override top and bottom padding of 5px
    padding: 0 0;
}

.btn-smallest-width + .dropdown-menu {
    min-width: 30px;
}

.dropdown-menu > .divider,
.dropdown-menu .divider{
    margin: 5px 0;
}

.dropdown-menu > li > a,
.dropdown-menu li a {

    color: $gray-darkest;
    display: block;
    line-height: 1;
    padding: 10px 20px;
    text-decoration: none;
    white-space: nowrap;

    // adding border so there is no change when the hover 'adds' a border
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
        background-color: $abn-highlight;
        border-top: 1px solid darken($abn-highlight, $btn-hover-darken-percentage);
        border-bottom: 1px solid darken($abn-highlight, $btn-hover-darken-percentage);
    }

    &:active {
        background-color: darken($abn-highlight,$btn-hover-darken-percentage);
        border-top: 1px solid darken($abn-highlight, $btn-active-darken-percentage);
        border-bottom: 1px solid darken($abn-highlight, $btn-active-darken-percentage);
    }

    &:focus {
        background-color: $abn-highlight;
    }

    &[ng-click] {
        cursor: pointer;
    }
}

.dropdown-header {
    color: $gray-darkest;
    font-size: $font-size-medium;
    font-weight: bold;
    padding: 8px 10px 0;
}

.ocf-dropdown-alt {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}

.ocf-dropdown-alt-expanded {
    box-shadow: $box-shadow;
    margin-top: -20px;
}

// link color in combination with icon
.btn-link.ocf-dropdown-icon,
.btn-link.ocf-dropdown-icon:active,
.btn-link.ocf-dropdown-icon:hover,
.btn-link.ocf-dropdown-icon:focus {
    color: $gray-darkest;
}
