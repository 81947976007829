// Styling for the aab-responsive-image component

.ocf-responsive-image-block {

    .img-responsive {

        width: auto;

        @media (max-width: $screen-sm - 1px) {
            margin-bottom: 20px;
        }
    
    }
}

// class for portrait images
.ocf-image-portrait {
	display: inline-table;
    vertical-align: top;
	width: 123px;
	margin: 0 10px 12px 0;

	@media (min-width: $screen-sm) {
    	margin-right: 20px;
    }

    &:last-child {
    	margin-right: 10px;
    }
    
    p {
        text-align: center;
        font-size: 12px;
        color: $gray;
        margin-bottom: 0px;
    }

}

.ocf-align-invert-sm {
    padding-right: 0px;
	@media (min-width: $screen-sm) {
    	text-align: right;
    }
}

.ocf-image-split {
    width: 123px;
}