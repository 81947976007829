//
// Added a hamburger menu which doesn't data-toggle, but uses responsiveness
// --------------------------------------------------

// Extensions

.navbar-hamburger {
    float: left;
    width: 55px;
    height: 55px;
    @media (min-width: $screen-md) {
        display: none;
    }
}

.btn-nav-expander,
.btn-nav-close {
    background:  $navbar-default-bg;
    padding: 3px  0 0 0;
    margin-left: 0px;
    width: 55px;
    height: 55px;
    color: $abn-contrast;
    border: none;
    text-align: center;
}

.btn-nav-expander .icon-bar,
.btn-nav-close .icon-bar {
    background-color: $abn-contrast;
    height: 3px;
    width: 53%;
    display: block;
    margin: 4px auto 0 auto;
    border-radius: $border-radius-small;
    border: 1px solid transparent;
}

.btn-nav-expander .small,
.btn-nav-close .small {
    margin-top: 3px;
}

.navbar-hamburger-expanded .container-fluid-nomax {
    min-height: 55px;
}

.navbar-hamburger-expanded .navbar-container {
    // animation
    right: calc(100% - (#{$menu-width-xs} + 55px));
    transition: right .3s ease-in-out;
    max-width: 100%;

    @media (max-width: $screen-xs - 1px) {
        //animation
        right: calc(100% - (#{$menu-width-xxs} + 55px));
    }

    @media (max-width: $screen-md-min - 1px) {
        height: auto;
    }
}

.navbar-hamburger-closed .navbar-container {
    transition: right .2s ease-out;

    @media (max-width: $screen-md-min - 1px) {
        height: 55px;
    }
}

.navbar-hamburger-expanded .navbar-main {
    @media (max-width: $screen-md-min - 1px) {
        display: block;
        height: 55px;
        padding-right: 55px;
        left: 0px;

        .container-fluid {
            margin: 0;
            padding-left: 0;
            width: $menu-width-xs;
            left: 0;
        }

        .navbar-nav {
            margin-right: 0;
            position: relative;
            z-index: 10;

            .ocf-segment-switch,
            .ocf-language-switch {
                list-style: none;
                padding: 0;
                background-color: $abn-action;

                li {
                    border-bottom: none;

                    a {
                        background-color: $abn-action;
                        font-size: 16px;
                        margin: 0;
                        min-height: 55px;
                        text-decoration: none;
                    }
                }
            }

            .ocf-segment-switch {
                li {

                    &:last-child {
                        position: relative;

                        &:after {
                            content: " ";
                            height: 1px;
                            width: calc(100% - 30px);
                            position: absolute;
                            bottom: -1px;
                            left: 15px;
                            background-color: $gray-light;
                        }
                    }

                    a {
                        display: block;
                        color: $gray-light;

                        &:focus,
                        &:hover {
                            background-color: $abn-primary-dark;
                            color: $abn-contrast;
                            font-weight: normal;

                        }
                    }
                }

                li.active a {
                    background: svg("ocf-icon-checkbox-check", ".primaryFill[fill]: #{$abn-contrast};") 97% no-repeat;
                    background-size: 20px;
                    color: $abn-contrast;

                    &:focus,
                    &:hover {
                        font-weight: bold;
                    }
                }
            }

            .ocf-language-switch {
                border-top: solid 1px $abn-action;
                width: 100%;
                padding: 17px 10px 38px 10px;
                text-align: right;

                &:after {
                    content: " ";
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    background-color: $gray-light;
                    z-index: -10;
                }

                 li {
                    width: 100px;
                    display: inline;
                    float: none;
                    padding: 0 3px 0 6px;
                    border-left: 1px solid $abn-contrast;

                    a {
                        padding: 0;
                        color: $gray-lighter;
                    }

                    &.active a {
                        color: $abn-contrast;
                    }

                    &:first-child {
                        border-left: none;
                    }
                 }
            }
        }
    }
}

.navbar-main {

    @media (max-width: $screen-md-min - 1px) {
        display: block;
        height: 100%;

        // set width of navbar
        width: $menu-width-xs;

        background: rgba(0,0,0,.2);
        z-index: 101;
        float: left;
        position: relative;

        .container-fluid {
            background: $abn-contrast;
            padding-right: 0px;
        }
    }

    @media (max-width: $screen-xs - 1px) {
        // set width of navbar
        width: $menu-width-xxs;
    }
}

.navbar-hamburger-expanded .navbar-main .container-fluid {

    @media (max-width: $screen-xs - 1px) {
        // set width of expanded navbar
        width: $menu-width-xxs;
    }

}

.navbar-hamburger-expanded .ocf-curtain,
.navbar-hamburger-closed .ocf-curtain {
    float: left;
    height: 100%;
    left:0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: $ocf-curtain;
}

.navbar-hamburger-expanded .ocf-curtain {
    opacity: 1;
}

.navbar-hamburger-closed .ocf-curtain {
    opacity: 0;

    // height will be set 0 after the sliding effect is over, so the curtain will be there
    height: 0 !important;
}
