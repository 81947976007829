//
// Modal
//

.modal {
    position: fixed;
    top: 0;
    transform: translate3d(0,0,0);
}

.modal-dialog {
    display: table;
    display: flex;
    width: 300px;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
	border: $gray-light;
    transform: none;
    height: 100%;
    height: 100vh;
    max-height: 100%;
    max-height: 100vh;
    padding: 40px 0;
    align-items: center;
}

.modal.fade .modal-dialog,
.modal.in .modal-dialog {
    transform: none;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 300px;
	background-color: $gray-lighter;
	border: 1px solid $gray-light;
	box-shadow: none;

}

.modal-header {
    flex-grow: 0;
	color: $brand-primary;
	height: 50px;
	line-height: 50px;
	margin-bottom: 20px;
	padding: 0 0;
	text-align: center;
    width: 100%;

	.modal-title {
        display: inline-block;
        float: none;
        margin: 0 auto;
        margin-top: -4px;
        padding-right: 0;
        vertical-align: middle;
        font-size: $font-size-h2;
	}
}

.modal-body {
    flex-grow: 6;
	border: none;
	color: $gray-darkest;
	padding: 0 ($grid-gutter-width / 2);
    display: flex;
    flex-direction: column;
    max-height: 250px;
    max-height: calc(100vh - 80px);
    transform: translate3d(0,0,0);

    .well {
        padding-top: 15px;
		padding-right: $grid-gutter-width / 2;
        padding-bottom: 18px;
        padding-left: $grid-gutter-width / 2;
        margin-left: 0;
        margin-right: 0;
        overflow-y: auto;
        max-height: 130px;
        max-height: calc(100vh - 220px);
        min-height: 0;
        width: (300px - $grid-gutter-width);
        -webkit-overflow-scrolling: touch;
        transform: translate3d(0,0,0);
	}
}
.modal-footer {
    flex-grow: 0;
	border: none;
	padding: 0 ($grid-gutter-width / 2) $grid-gutter-width ($grid-gutter-width / 2);
    max-height: 70px;
    width: 100%;
    align-items: flex-end;

    .btn-primary {
        float: right;
    }

    .btn-default {
        float: left;
    }
}


@media (min-width: $screen-sm-min) {
  // Automatically set modal's width for larger viewports

    .modal-body .modal-dialog {
        margin: 0 auto;
    }
}
body {
    &.modal-open {
    overflow :hidden;
    }
}

.modal-loader {

    .well {
        text-align: center;
        min-height: 214px;
    }

    .modal-animation {
        width: 100px;
        height: 100px;
        margin: 0 auto 10px;
    }
}

.modal-backdrop.in {
    opacity: 0.6 !important;
}