// Links

a {
    text-decoration: underline;
}

body {

    /* For Firefox */
    word-wrap: break-word;

    overflow-wrap: break-word;
    word-break: break-word;
    // ie fix
    -ms-word-wrap: break-word;
}

p {
    margin: 0 0 10px;
}


.radio label,
.radio fieldset fieldset.ocf-nested-group legend,
fieldset fieldset.ocf-nested-group .radio legend {
    vertical-align: top;
}

html {
    /* Instead of scrollbar on top of content display scrollbar next to content in IE */
    -ms-overflow-style: scrollbar;
}