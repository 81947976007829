/* styling for overlays to position sticky header, footer & controls
*/

.ocf-overlay {
    position: fixed;
    top: 0;
    left: 0;
    max-height: calc(100vh - 57px);
    width: 100vw;
    z-index: 1000;
}

.ocf-overlay-footer,
.ocf-overlay-body {
    position: fixed;
    width: 100%;
    padding: 10px;
}

.ocf-overlay-body {
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 57px);
    bottom: 57px;
    position: fixed;
    background: $abn-contrast;
}

.ocf-overlay-footer {
    bottom: 0;
    height: 57px;
    width: 100%;
    overflow: hidden;
    background: $abn-contrast;
    border-top: 1px solid $gray-light;
}

.sticky-bottom {
    background: $abn-contrast;
    border-top: 1px solid $gray-light;
    bottom: 0;
    height: 57px;
    left: 0;
    overflow: hidden;
    padding: 10px 15px;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: 99;
}
.sticky-hide {
    display: none;
}
