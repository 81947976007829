//
// Guideballoons


// --------------------------------------------------


.ocf-balloon-guide {
    position: relative;
    width: 100%;
    text-align: left; // Reset given new insertion method
    background-color: $popover-bg;
    background-clip: padding-box;
    border: 2px solid $popover-fallback-border-color;
    border: 2px solid $popover-border-color;
    border-radius: 3px;
    @include box-shadow(none);

    // Overrides for proper insertion
    white-space: normal;

    // Offset the popover to account for the popover arrow
    &.bottom  {
        margin-top: 10px;
    }
}

.ocf-balloon-guide-inner {
    padding: 15px;
    color: $gray-darkest;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.ocf-balloon-guide > .arrow {
    &,
    &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
}

.ocf-balloon-guide > .arrow {
    border-width: $popover-arrow-outer-width;
}

.ocf-balloon-guide > .arrow:after {
    border-width: $popover-arrow-width;
    content: "";
}

.ocf-balloon-guide {
    &.bottom > .arrow {
        left: 30px;
        margin-left: -$popover-arrow-outer-width;
        border-top-width: 0;
        border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
        border-bottom-color: $popover-arrow-outer-color;
        top: -$popover-arrow-outer-width;

        &:after {
            content: " ";
            top: 2px;
            margin-left: -$popover-arrow-width;
            border-top-width: 0;
            border-bottom-color: $popover-arrow-color;
        }
    }
}
