//
// progressbar
// --------------------------------------------------

.ocf-progressbar-container {
    height: 45px;
}

.ocf-progressbar {
    line-height: 0;
    width: 100%;

    .ocf-progressbar-content {
        padding-top: 13px;

        @media (max-width: $screen-md - 1px) {
            padding-top: 10px;
        }
    }

    .ocf-progressbar-bar {
        background-color: $gray-light;
        border-radius: 2px;
        box-shadow: none;
        height: 5px;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;

        .progress-bar {
            background-color: $abn-additional;
            border-radius: 2px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 5px;
            min-width: 0px;
        }
    }

    .ocf-progressbar-value {
        float: right;
        margin-top: 2px;
        padding: 0;
        text-align: right;
        white-space: nowrap;

        @media (max-width: $screen-md - 1px) {
            margin-top: -1px;
        }
    }

    .ocf-progressbar-value-value {
        @extend h3;
        color: $gray-dark;
        margin-right: -4px !important; // close gap with max value
    }

    .ocf-progressbar-value-max {
        color: $gray;
    }
}