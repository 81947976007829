/**
 * ABN AMRO Footer
 * Page layout, footer
 * This part styles the footer and its content
 */


// Footer

html {
    background: $ocf-gray-branding;
}

footer {
    background: $ocf-gray-branding;

    .container-fluid {
        display: flex;
        flex-flow: row wrap;
        padding-top: 20px;

        @media (min-width: $screen-md) {
            flex-flow: row nowrap;
            padding-top: 40px;
        }
    }
}


// Copyright statement

footer .ocf-copyright {
	color: $gray-dark;
	line-height: 1;
    margin: 0 5px 37px 5px;
    order: 3;

    @media (min-width: $screen-md) {

        float: none;
        margin: 0px 18px 37px 30px;
        min-width: 173px;
        width: 173px;
        flex-basis: 154px;
        flex-grow: 0;
        float: right;
        clear: both;

    }
}


// social media

footer .ocf-social-media {
    display: block;
    height: auto;
    order: 1;
    width: auto;
    margin: 0 5px 13px 5px;
    min-height: ($icon-size-large + 7);

    @media (min-width: $screen-md) {
        float: right;
        height: 50px;
        margin: -8px 5px 15px 5px;
        order: 2;
        flex-grow: 1;
        // the size is based on 7 icons with 7 px margin in between
        // size can have decimals. The + 1 fixes any issues with this
        flex-basis: ((7 * $icon-size-large) + (6 * 7) + 1);
        max-width:  ((7 * $icon-size-large) + (6 * 7) + 1);
        min-width:  ((7 * $icon-size-large) + (6 * 7) + 1);

        ul {
            height: 30px;
            margin-bottom: 0;
        }
    }

    @media print {
        display: none;
    }
}

footer .ocf-social-media ul {
    list-style: none;
    padding-left: 0;

    li {
        background-color: $ocf-socialmedia-bg;

        border-radius: 2px;
        float: left;
        display: inline-block;
        margin: 0 7px 7px 0;

        a {
            @include square($icon-size-large);
            background-size: $icon-size-small;
        }

        &.ocf-facebook:hover {
            background-color: $ocf-facebook;
        }

        &.ocf-google:hover {
            background-color: $ocf-google;
        }

        &.ocf-instagram:hover {
            background-color: $ocf-instagram;
        }

        &.ocf-linkedin:hover {
            background-color: $ocf-linkedin;
        }

        &.ocf-rss:hover {
            background-color: $ocf-rss;
        }

        &.ocf-twitter:hover {
            background-color: $ocf-twitter;
        }

        &.ocf-youtube:hover {
            background-color: $ocf-youtube;
        }
    }

    li:last-child {
        margin-right: 0;
    }

}


// corporate navigation
footer .ocf-corp-nav {
    max-width: 100%;
    width: auto;
    order: 2;
    margin: 0 5px 12px 5px;
    flex-grow: 2;


    @media (min-width: $screen-md) {
        float: left;
        order: 1;
        margin: 0 23px 32px 0;
        width:  calc(100% - 500px);
        max-width: calc(100% - 400px);
    }

    @media print {
        display: none !important;
    }
}


footer .ocf-corp-nav ul.nav.nav-pills {
    width: auto;
    margin: 0;
    display: inline-block;


    @media (min-width: $screen-md) {
        margin-left: 18px;
    }

    li {
        display: inline-block;
        float: left;

        &:after {
            color: $abn-action;
            content:"|";
            display: inline-block;
            padding-right: 7px;
        }
    }

    li:last-of-type {

        &:after {
            display: none;
        }

        a {
            padding-right: 0;
        }
    }


    li a {
        color: $abn-action;
        display: inline-block;
        padding-top: 0;
        padding-right: 3px;
        text-decoration: none;

        &:hover {
            color: $abn-primary-dark;
            text-decoration: underline;
        }
    }
}