/**
 * This theme imports the components from the bootstrap-sass-official bower_components file by file.
 *
 * ABN Amro overwrites are imported here or unused components are commented out.
 *
 * Document custom imports!
 * Outcomment not used bootstrap css, don't remove them
 *
 */


//
// Core variables and mixins
// Override default Bootstrap variables and mixins with ABN AMRO's value for colours, sizes, etc ;
// since Bootstrap uses !default custom variables need to be declared first
//
// Declare theme specific variables mixins
// Import default Bootstrap variables and mixins
//

@import "_theme_variables";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_variables";

@import "_theme_mixins";
// The mixins are not loaded from the bower component out of the box, but by a _theme_mixins Sass file,
// in order to be able to control and customize the mixins
//
// @import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_mixins";
//

//
// Reset and dependencies
//
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_normalize";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_print";

// We don't need glyphicons, since their style is not ABN Amro, not importing reduces file-size
// @import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_glyphicons";
// Hence we use a similar file
//@import "_theme_icons.scss";
//
// Core CSS
// We can import these files without overrides, since they are mainly constructed based on the variables
//
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_scaffolding";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_type";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_code";
// @import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_grid";
// overrule the grid
@import "_theme_grid";

@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_tables";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_forms";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_buttons";


//
// Components
//
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_component-animations";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_dropdowns";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_button-groups";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_input-groups";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_navs";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_navbar";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_breadcrumbs";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_pagination";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_pager";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_labels";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_badges";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_jumbotron";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_thumbnails";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_alerts";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_progress-bars";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_media";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_list-group";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_panels";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_responsive-embed";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_wells";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_close";
// overrule the panels
@import "_theme_panels";

//
// Components w/ JavaScript
//
// These are necessarry for the Angular UI Bootstrap version.
// TODO If we end up not using one of these components we'll remove this later on
//
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_modals";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_tooltip";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_popovers";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_carousel";


//
// Utility classes
//
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_utilities";
// @import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/_responsive-utilities";
@import "theme_responsive-utilities";


// themed versions and additions for the default components imported above here
@import "theme_accordion";
@import "theme_buttons";
@import "theme_forms";
@import "theme_navbar";
@import "theme_navbar_hamburger";
@import "theme_navs";
@import "theme_navbar-vertical";
@import "theme_scaffolding";
@import "theme_type";
@import "theme_wells";
@import "theme_alerts";
@import "theme_tooltip";
@import "theme_popovers";
@import "theme_update-loader";
@import "theme_guideballoon";
@import "theme_modal";
@import "theme_card";
@import "theme_dropdown";
@import "theme_datepicker";
@import "theme_i_text";
@import "theme_bill";
@import "theme_logout-widget";
@import "theme_tiles";
@import "theme_pin5";
@import "theme_businesscard";
@import "theme_iban";
@import "theme_forms-nested-fields";
@import "theme_progressbar";
@import "theme_response-code";
@import "theme_aab-components";
@import "theme_overlay";
@import "theme_link";
@import "theme_responsive-image-block";
@import "theme_step-indicator";
@import "theme_compare";
@import "theme_aab-currency-input";
@import "theme_totals";
@import "theme_banner";
@import "theme_notification";

// external lib
@import "_angular-busy";
//rz slider customized file converted to SASS
@import "_rzslider";

@import "card";

