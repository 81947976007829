.ocf-tiles {
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
}

.ocf-tiles-half {
    @media (max-width: $screen-sm - 1px) {
        margin-bottom: 0 !important;
    }
}

.ocf-tile {
    background-color: $abn-contrast;
    padding: 15px ($grid-gutter-width / 2);
    float: left;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid $gray-light;

    @media (min-width: $screen-sm-min) {
        padding: 15px $grid-gutter-width;
    }

    p,
    h5 {
        margin: 0;
    }

    .ocf-logo-service {
        float: left;
        margin-top: 2px;
    }

    &.ocf-tile-alert {
        background-color: $brand-warning;
    }

    &.ocf-tile-error {
        background-color: $abn-contrast;
        border: 1px solid $abn-attention;
    }
}


.ocf-tile-oneline {

    .ocf-tile-info {
        margin-top: 6px;
    }

    .ocf-tile-additional-info {
        margin-top: 3px;
        margin-bottom: 6px;
    }

    .ocf-logo-service {
            margin-top: 3px;
    }
}


.ocf-tile-half {
    margin-bottom: 20px;

    .ocf-logo-service ~ .ocf-tile-paragraph {
        overflow: hidden;
    }
}


.ocf-tile-merged {
    margin-bottom: 0;
    border-bottom: none;
    position: relative;

    /* Required for Down Triangle */
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-bottom: 0;
      bottom: -12px;
    }

    &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px ;
        border-color: $gray-light transparent transparent transparent;
        left: 50%;
        left: calc(50% - 5px);
        bottom: -20px;
    }

    &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        border-color: $abn-contrast transparent transparent transparent;
        left: 50%;
        left: calc(50% - 3px);
        bottom: -16px;
    }
}

.ocf-tile-interactive {
    border: none;
    box-shadow: $box-shadow;

    &.ocf-tile-selected {
        background-color: $abn-highlight;
    }

    &:hover,
    &:focus {
        background-color: $abn-highlight;
        cursor:pointer;
    }

    &:active {
        background-color: darken($abn-highlight, $btn-hover-darken-percentage);
    }

    &.disabled,
    &[disabled] {

        &:hover,
        &:focus {
            background-color: $abn-contrast;
            cursor: auto;
        }
    }
}


.ocf-tile-interactive.disabled,
.ocf-tile-interactive[disabled] {
    box-shadow: none;
    border: 1px solid $gray-light;

    &:hover,
    &:focus,
    &:active {
        background-color: $abn-contrast;
        cursor: auto;
    }
}

.ocf-tile-info {
    width: 100%;
}

.ocf-tile-editable {

    &.ocf-tile-action {
        padding-top: 12px;
    }

    .ocf-logo-service {
        margin-top: 5px;
    }

    .ocf-tile-primary-info {
        top: 2px;
        position: relative;
    }
}


.ocf-tile-editable .ocf-tile-name {

    h5 {
       padding-right: 25px;
       display: inline;

        &:before {
            display: inline-block;
            content: '';
            @include square($icon-size-small);
            background-image: svg("ocf-icon-pencil", ".primaryFill[fill]: #{$icon-neutral};");
            background-size: contain;
            margin: 3px 5px 0 0;
            float: left;
        }
    }

    span:hover {
        h5:before {
            background-image: svg("ocf-icon-pencil", ".primaryFill[fill]: #{$icon-additional};");
        }
    }
}

.ocf-tile-edit {
    margin-top: 3px;
}


.ocf-tile-input {
    border-color: $abn-action;
}

.ocf-tile-disabled {
    color: $gray;
    background-color: $gray-lighter;
    box-shadow: none;
    border: 1px solid $gray-light;

    &:hover,
    &:focus,
    &:active,
    &[disabled]:hover,
    &[disabled]:focus {
        background-color: $gray-lighter;
        box-shadow: none;
        border: 1px solid $gray-light;
        cursor: auto;
    }

    .ocf-tile-primary-info,
    .ocf-tile-name,
    .ocf-tile-additional-info {
        color: $gray !important;
    }
}

// SECONDARY ACTION ICONS

// keep old way of tile guy, since this is implemented in refactored widgets, who don't use aab-tile directive right now
// - Editable
.ocf-tile-action-icon-sortable {

    span.glyphicon {

        background-image: svg("ocf-icon-move", ".primaryFill[fill]: #{$icon-neutral};");
    }
}

.ocf-tile-editable:hover {

    // only the box shadow when hover and if there is an action
    &.ocf-tile-action:hover {
        box-shadow: $box-shadow-active;
    }

    .ocf-tile-action-icon-sortable > span.glyphicon {
        background-image: svg("ocf-icon-move", ".primaryFill[fill]: #{$icon-additional};");
    }
}


// - Dropdown
.ocf-tile-action-icon-dropdown {

    span.glyphicon {
        background-image: svg("ocf-icon-down", ".primaryFill[fill]: #{$icon-neutral};");
    }
}

// - Dropdown
.ocf-tile-action-icon-delete {

    span.glyphicon {
        background-image: svg("ocf-icon-trash", ".primaryFill[fill]: #{$icon-neutral};");
    }
}
.ocf-tile-action-icon-delete:hover {

    span.glyphicon {
        background-image: svg("ocf-icon-trash", ".primaryFill[fill]: #{$icon-attention};");
    }
}

.ocf-tile-dropdown:hover {

    .ocf-tile-action-icon-dropdown > span.glyphicon {
        background-image: svg("ocf-icon-down", ".primaryFill[fill]: #{$icon-additional};");
    }
}

// NEW WAY OF SETTING HOVER ACTIONS
// Show Hover state of secondary icon on the secondary action area only

.ocf-tile-action-icon:hover .glyphicon {

    &.glyphicon-trash {
        background-image: svg("ocf-icon-trash", ".primaryFill[fill]: #{$icon-additional};");
    }

    &.glyphicon-move {
        background-image: svg("ocf-icon-move", ".primaryFill[fill]: #{$icon-additional};");
    }

    &.glyphicon-info-sign {
       background-image: svg("ocf-icon-info-sign", ".primaryFill[fill]: none; .secondaryFill[fill]: #{$icon-additional};");
    }
}

// Show Hover state of secondary icon on the whole tile

.ocf-tile-action:hover .glyphicon {

    &.ocf-icon-down {
        background-image: svg("ocf-icon-down", ".primaryFill[fill]: #{$icon-additional};");
    }

    &.glyphicon-chevron-right {
        background-image: svg("ocf-icon-chevron-right", ".primaryFill[fill]: #{$icon-additional};");
    }
}



.ocf-tile-additional-info {
   float: left;
   clear: right;
   font-size: $font-size-small;
   color: $gray;
   @include text-truncate(100%);
   max-width: 90%;
   max-width: calc(100% - #{($grid-gutter-width)});
}


// Override of length (1)
.ocf-logo-service ~ .ocf-tile-additional-info {
    max-width: calc(100% - #{($grid-gutter-width)} - #{($icon-size-logoservice)});
}

// Override of length (2)
.ocf-tile-action.ocf-tile-compact {
    .ocf-tile-additional-info {
        @media (min-width: $screen-xs) {
             max-width: 100%;
        }

    }
}

// Override of length (3)
.ocf-tile-action.ocf-tile-checkbox {
    .ocf-tile-additional-info {
        max-width: 87%;
        max-width: calc(100% - 30px);
    }

    .ocf-logo-service ~ .ocf-tile-additional-info {
        max-width: 67%;
        max-width: calc(100% - 86px);
    }

}



// override
.ocf-tile-input {

    .input-group {
        margin-top: 7px;
        margin-bottom: 7px;
    }
}


.ocf-tile-name,
.ocf-tile-name h5 {
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
    position: relative;
}


.ocf-tile-primary-info {
    color: $gray-darkest;
    float: right;
    margin-top: 0;
    white-space: nowrap;
}


.ocf-tile-additional-info-separator {
    margin-left: 10px;
    margin-right: 10px;
}


.ocf-tile-col .ocf-tile-primary-info {
    padding-right: 55px;
}


.ocf-tile-block, .ocf-tile-col {
    margin-bottom: 0px;

    .ocf-tile{
        padding-right: 0px;
        margin-bottom: 2px;
    }
}

.ocf-tile-action {
    padding-right: 0;
}

.ocf-tile-half.ocf-tile-action > .ocf-tile-action-icon {
    padding-top: 0;
    padding-bottom: 0;
}

.ocf-dropdown-alt {

    @media (max-width: $screen-sm) {
        margin-top: 0;
    }

    .ocf-tile {
        margin-bottom: 0px;
    }
}

// make way for the dropdown status
.ocf-tile-action > .ocf-tile-info,
.ocf-tile-action > .ocf-tile-additional-info,
.ocf-tile-action > .ocf-tile-paragraph {
    padding-right: 55px;
}

.ocf-tile-action-icon {
    display: inline-block;
    border-left: 1px solid transparent;
    padding: 16px 0 14px 0;
    width: 45px;
    text-align: center;
    right: 10px;
    position: absolute;

    &.ocf-tile-action-icon-separator {
        border-color: $gray-light;

        // make the left border exceed the padding of the tile
        &:after {
            display: block;
            position: absolute;
            width: 1px;
            border-left: 1px solid $gray-light;
            content: '';
            left: -1px;
        }

        // make the click area to exceed the icon itself, because its parent (the tile) has padding)
        &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: -15px;
            height: calc(100% + 30px);
            width: calc(100% + 1px);
            margin-left: -1px;
        }
    }

    &[ng-click=""] {
        border-color: transparent;

        &:after {
            display: none;
        }
    }

}


.ocf-dropdown-alt-expanded {
    margin-top: -23px;
    max-height: 417px;
    overflow: hidden;
    overflow: auto;
    position: absolute;
    width: 80%;
    width: calc(100% - #{($grid-gutter-width)});
    max-width: 80%;
    max-width: calc(100% - #{($grid-gutter-width)});
    z-index: 9999;

    .ocf-tile-interactive {
        box-shadow: none;
        border-top: 1px solid $gray-light;
        border-right: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
    }

    .ocf-tiles .ocf-tile::last-child {
        border-bottom-width: 1px;
    }

    .ocf-tiles {
        display: inline;
    }

    .ocf-tiles,
    .ocf-tile {
        margin-bottom: 0px;
        border-bottom-width: 0;
    }
    //margin-bottom: 20px;
}


// compact
.ocf-tile-compact {

    .ocf-tile-action-icon {
        padding: 7px 0;
    }
}


// Tile Dropdown-unselected
.ocf-tile-dropdown-unselected {

    &.ocf-tile-interactive {
        min-height: 83px;

        .ocf-tile-name {
            color: $gray-darkest;
            font-weight: normal;
            margin-top: 16px;
            margin-bottom: 11px;
        }
    }

    &.ocf-tile-oneline {
        min-height: 82px;

        .ocf-tile-name {
            margin-top: 16px;
            margin-bottom: 10px;
        }
    }

    &.ocf-tile-compact {
        min-height: 67px;
        padding-bottom: 0;

        .ocf-tile-name {
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }

    &.ocf-tile-bcselector {
        min-height: 80px;

        .ocf-tile-name {
            //margin-top: 16px;
            //margin-bottom: 10px;
        }
    }

    .ocf-tile-info {
        margin-top: 0;
    }
}


// tile in widget
.panel .ocf-dropdown-alt-expanded {
    margin-top: -3px;
}


.panel .ocf-dropdown-alt {
    margin-bottom: 0;
}


// Tile with warning
.ocf-dropdown-alt.has-warning {

    margin-bottom: -5px;

    .ocf-tile {
        border: 1px solid $brand-danger;
    }
}


.ocf-dropdown-alt-expanded.has-warning {
    margin-top: 2px;
}


// dropdown tile business contact selector
.ocf-tile-bcselector {
    .ocf-tile-name {
        margin-top: 16px;
    }
}

// overrides for browsers that do not support the css calc property
@media (max-width: $screen-xxs-max) {

    .ocf-dropdown-alt-expanded {
        width: 100%;
        width: calc(100% - 20px);
        max-width: ($screen-xxs-max - 20px);
        max-width: calc(100% - 10px);
    }

    .form-group .ocf-dropdown-alt,
    .form-group .ocf-dropdown-alt-expanded {
        max-width: 278px;
        max-width: calc(100%);
    }
}

@media (min-width: xs-min) and (max-width: $screen-xs-max) {

    .ocf-dropdown-alt-expanded {
        width: 100%;
        width: calc(100% - 20px);
        max-width: ($screen-xs-max - 20px) ;
        max-width: calc(100% - 10px);
    }

    .form-group .ocf-dropdown-alt,
    .form-group .ocf-dropdown-alt-expanded {
        max-width: ($screen-xs-max - 30px);
        max-width: calc(100%);
    }
}



@media (min-width: $screen-xs-min) {

    .ocf-dropdown-alt-expanded,
    .form-group .ocf-dropdown-alt-expanded {
        max-width: 100%;
        max-width: calc(100% - 20px);
        width: 100%;
    }
}

.ocf-tile.ocf-tile-notification {
    border: none;
    border-left-width: 5px;
    border-left-color: $abn-additional;
    border-left-style: solid;
    &.ocf-tile.ocf-tile-notification-alert {
        border-left-color: $abn-attention;
    }
}

.ocf-tile.ocf-tile-checkbox {
    padding-left: 10px;

    &.ocf-tile-notification {
        padding-left: 5px;
    }
    &.ocf-tile-notification-alert {
        padding-left: 5px;
    }
    .checkbox {
        float: left;
        margin: 2px 1px 0 4px;
    }

    .ocf-icon-reply {
        margin-right: 5px;
    }

    .checkbox-tile {
        margin: 2px 5px 0 4px;
    }

    .ocf-logo-service {
       float: left;
    }

    label {
        padding: 0;
        margin-right: 5px;
    }
}

.ocf-tile.ocf-tile-checkbox.ocf-tile-compact {
    > .checkbox {
        margin-top: -6px;
        margin-bottom: 10px;
        max-height: 22px;
    }

    .ocf-tile-additional-info {
        max-width: 90%;
        max-width: calc(100% - #{$grid-gutter-width} - 20px);
    }
}

.ocf-stock-tile-primary-info {
    color: $gray-darkest;
}

.ocf-stock-tile-name  {
    color: $gray-darkest;
    white-space: normal;
    padding-right: 5px;
    position: relative;
    display: inline;
}

.ocf-tile-status-info {
    color: $gray;
    float: right;
    margin-top: 0;
    white-space: nowrap;
}

.ocf-stock-tile-currency-info {
   float: right;
   text-align: right;
   font-size: $font-size-small;
   color: $gray;
   @include text-truncate(100%);
   max-width: 90%;
   max-width: calc(100% - #{($grid-gutter-width)});
}

.ocf-stock-tile-quantity-info {
   float: left;
   text-align: left;
   font-size: $font-size-small;
   color: $gray;
   @include text-truncate(100%);
   max-width: 90%;
   max-width: calc(100% - #{($grid-gutter-width)});
}

.ocf-stock-tile-additional-info {
    float: right;
    clear : left;
    text-align: right;
    font-size: $font-size-small;
    color: $gray;
    @include text-truncate(100%);
    max-width: 90%;
    max-width: calc(100% - #{($grid-gutter-width)});
}

.ocf-stock-tile-details-additional-info {
    width: 100%;
    display: inline-block;
    padding-top: 20px;
    color: $gray-darkest;
}

.ocf-stock-tile-negative {
    color: $ocf-negative;
}

.ocf-stock-tile-positive {
    color: $ocf-positive;
}

// grouping of tiles
.ocf-tiles.ocf-tiles-group {
    margin-bottom: 20px;

    // remove bottom margin from individual tiles in a group
    .ocf-tile {
        margin-bottom: 0;

        &.ocf-tile-readonly:not(:first-child),
        &.ocf-tile-disabled:not(:first-child) {
            border-top-width: 0;
        }

        &.ocf-tile-readonly.ocf-tile-merged  + .ocf-tile,
        &.ocf-tile-disabled.ocf-tile-merged  + .ocf-tile        {
             border-top-width: 1px;
        }

        &.ocf-tile-interactive:not(:first-child) {
            box-shadow: 0 -8px 0 0 $abn-contrast , $box-shadow;
            border: 1px solid $gray-light;
            border-width: 1px 0 1px 0;
        }

        &.ocf-tile-interactive:hover + .ocf-tile-interactive,
        &.ocf-tile-interactive:focus + .ocf-tile-interactive  {
            box-shadow: 0 -8px 0 0 $abn-highlight , $box-shadow;
        }

        &.ocf-tile-interactive:active + .ocf-tile-interactive {
            box-shadow: 0 -8px 0 0 #cbffff , $box-shadow;
        }
    }
}

.ocf-tile-separator {
    border-top: 1px solid $gray-light;
    margin: 22px -5px 12px -5px;
    @media (min-width: $screen-sm-min) {
        margin: 22px -10px 12px -10px;
    }
}
