// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, $btn-hover-darken-percentage);
        border-color: darken($border, $btn-hover-darken-percentage);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
    background-color: darken($background, $btn-active-darken-percentage);
    border-color: darken($border, $btn-active-darken-percentage);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}