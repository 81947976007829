.well-notification-info {
    margin-top: 10px;
    background-color: $abn-notification;
    margin-left: 0px;
    margin-right: 0px;
}

.well-notification-success {
    margin-top: 10px;
    background-color: $abn-successback;
    margin-left: 0px;
    margin-right: 0px;
}

.well-notification-warning {
    margin-top: 10px;
    background-color: $abn-warningback;
    margin-left: 0px;
    margin-right: 0px;
}

.well-notification-alert {
    margin-top: 10px;
    background-color: $abn-alertback;
    margin-left: 0px;
    margin-right: 0px;
}

p {
   &.ocf-notify{
        margin: -26px 10px 0px 27px;
    }
}

.notification-icon {
    margin-top: -10px;
}

.notification-close {
    width: 8px;
    height: 19px;
    @media (min-width: $screen-sm) {
        margin-top: -16px;
        margin-right: -9px;
    }
    @media (max-width: $screen-xs) {
        margin-top: -16px;
    }
}

