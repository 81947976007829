// Styling for aab-link
.ocf-link {
    .ocf-icon-small {
        top: 5px;
    }
	
    a,
    a:hover,
    a:focus {
        outline: none;
        text-decoration: none;
		
        .ocf-link-details {
            color: $gray-darkest;
        }
    }
    .ocf-link-name {
        text-decoration: underline;
        color: $abn-action;

        &:hover {
            color: $abn-attention;
        }
    }
}
