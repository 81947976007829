//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
  .card-header,
  .card-footer {
  }
  .card-header,
  .card-footer,
  .card-title,
  .card-subtitle,
  .card-blockquote {
    color: $abn-contrast;
  }
  .card-link,
  .card-text,
  .card-blockquote > footer {
    color: $abn-contrast;
  }
  .card-link {
    &:focus,
    &:hover {
      color: $card-link-hover-color;
    }
  }
}
