/**
 * ABN Amro theme mixins overwrites are made here
 */
 // Mixins
// --------------------------------------------------


// Utilities
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/hide-text";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/opacity";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/image";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/labels";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/reset-filter";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/resize";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/responsive-visibility";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/size";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/tab-focus";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/text-emphasis";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/text-overflow";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/vendor-prefixes";

// Components
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/alerts";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/buttons";
/* overwrite of the button mixins, to add the correct shades to button states */
@import "mixins/theme_buttons";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/panels";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/pagination";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/list-group";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/nav-divider";

/* overwrite of the forms mixins, to add background colors to validation states */
@import "mixins/theme_forms";
// @import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/forms";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/progress-bar";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/table-row";

// Skins
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/background-variant";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/border-radius";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/gradients";

// Layout
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/clearfix";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/center-block";
@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/nav-vertical-align";


// Complete replacement, since the xxs-class is added to the grid

@import "mixins/theme_grid-framework";
//@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/grid-framework";


@import "../../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins/grid";
// Added extra mixins to create the xxs columns
@import "mixins/theme_grid";

// Added long text truncate mixin
@import "mixins/theme_truncate";

// Added mixin to create card variants
@import "mixins/cards";