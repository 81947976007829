/**
 * ABN AMRO Doormat
 * Page layout, doormat
 * This part styles the doormat and its content
 */
.ocf-doormat {
    background-color: $abn-action;
    > .container-fluid {
        padding: 20px 10px;

        @media (min-width: $screen-md) {
            padding: 20px 24px;
        }

        > div {
            margin-bottom: 12px;
            display: inline-block;
            vertical-align: top;
            margin-left: -4px;
            float: none;

            @media (max-width: $screen-xs) {
                &:last-child {
                    margin-bottom: 13px;
                }
            }
        }
    }

    a,
    h4 {
        color: $abn-contrast;
        text-decoration: none;
    }

    ul.ocf-linklist li a {
        background-image: svg("ocf-icon-link-arrow", ".primaryFill[fill]: #{$abn-contrast};");
        &:hover {
            text-decoration: underline;
        }
    }
}
