//
// Card
// --------------------------------------------------


.ocf-card {
    @include box-shadow($box-shadow);
    border-radius: 8px;
    margin-bottom: $grid-gutter-width;
    max-width: 345px;
    margin-right: auto;
    margin-left: auto;

    .ocf-card-block {
        background-image: url(#{$assetPath}/ocf-card-betaalpas.svg);  //fallback
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px 8px 0 0;
        color: $gray-darkest;
        font-size: $font-size-base;
        padding: 15px ($grid-gutter-width / 2) 15px ($grid-gutter-width / 2);
        text-shadow: $text-shadow;

        @media (min-width: $screen-sm-min) {
            padding-right: $grid-gutter-width;
            padding-left: $grid-gutter-width;
        }

        @media (max-width: $screen-xxs) {
            max-height: 140px;
        }

        p {
            text-shadow: $text-shadow;
            color: $gray-darkest;
        }

        &.ocf-card-betaalpas {
            background-image: svg("ocf-card-betaalpas");
            &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                svg("ocf-card-betaalpas");
            }
        }

        &.ocf-card-jongerenpas {
            background-image: url(#{$assetPath}/ocf-card-jongerenpas.svg);
            &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                url(#{$assetPath}/ocf-card-jongerenpas.svg);
            }
        }

        &.ocf-card-kredietpas {
            background-image: svg("ocf-card-kredietpas");
            &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                svg("ocf-card-kredietpas");
            }
        }

        &.ocf-card-meespiersonpas {
            background-image: svg("ocf-card-meespiersonpas");
             &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                svg("ocf-card-meespiersonpas");
            }
            color: $abn-contrast;

            p {
                color: $abn-contrast;
            }
        }

        &.ocf-card-preferredbankingpas {
            background-image: svg("ocf-card-preferredbankingpas");
            color: $abn-contrast;
            &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                svg("ocf-card-preferredbankingpas");
            }

            p {
                color: $abn-contrast;
            }
        }

        &.ocf-card-spaarpas {
            background-image: svg("ocf-card-spaarpas");
            &.ocf-lock-overlay {
                background-image: url(#{$assetPath}/ocf-card-lockoverlay.svg),
                svg("ocf-card-spaarpas");
            }
        }
    }

    .ocf-card-title {
        display: inline-block;
        float: right;
        line-height: 10px;
        margin-bottom: 10px;
        position: relative;
        right: 0;
    }

    .ocf-card-id {
        display: inline-block;
        line-height: 16px;
    }

    .ocf-card-cardnumber {
        display: inline-block;
        float:right;
        line-height: 16px;
        margin-top: 2px;
    }

    .ocf-card-cardtext {

        margin-top: 55px;
        margin-bottom: 15px;
        height: 45px;
        position: relative;
        vertical-align: bottom;


        @media (max-width: $screen-xxs) {
            margin-top: 30px;
        }

        p {
            line-height: 16 px;
            margin: 0 0;
        }

        p:last-child {
            bottom: 0;
            position: absolute;
        }
    }

    .ocf-card-alert .alert {
        margin: 0;
        border-bottom: 1px solid $gray-light;
        padding-top: ($grid-gutter-width/2);
        padding-bottom: ($grid-gutter-width/2);
        font-size: $font-size-small;
    }

    .ocf-card-options {
        border-radius: 0 0 8px 8px;
        height: 60px;

        .btn-default {
            border-left: 1px solid $gray-light;
            color: $gray-darker;
            font-size: $font-size-small;
            height: 60px;
            padding: 10px;

            &:hover {
                background-color: $gray-lighter;

                &.inactive {
                    cursor: default;
                }
            }

            &:active {
                background-color: darken($gray-lighter, $btn-hover-darken-percentage);
            }

            &.inactive {
                background-color: $abn-contrast;

                .ocf-btn-txt {
                    color: $gray;
                }
            }

            .glyphicon {
                width: 37px;
            }

            .ocf-btn-txt {
                margin-top: 3px;
                overflow: hidden;
            }
        }

        .btn-group .btn-group:first-child,
        .btn-group .btn-group:first-child :hover,
        .btn-group .btn-group:first-child .inactive {
            border-radius: 0 0 0 8px;

            .btn-default {
                        border-left: none;
            }
        }

        .btn-group .btn-group:last-child,
        .btn-group .btn-group:last-child :hover,
        .btn-group .btn-group:last-child .inactive {
            border-radius: 0 0 8px 0;

            .glyphicon {
                border-radius: 0 0;
            }
        }
    }

    // adjustments for button-bar generic component
    .ocf-card-options .btn-default .ocf-btn-text {
        font-size: $font-size-small;
    }
    .ocf-button-icon {
        @extend .ocf-btn-icon;
        .glyphicon {
            margin-right: 0;
        }
    }

    .btn-group-alt .ocf-btn-txt,
    .ocf-btn-navigation .ocf-btn-txt,    {
        display: block;
        text-align: center;

        @media (min-width: $screen-sm) {
            font-size: $font-size-small;
        }
    }
    // end of adjustments for button-bar generic component
}
