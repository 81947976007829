//
// Base styles
//

.card {
  position: relative;
  display: block;
  margin-bottom: $card-spacer-y;
  background-color: $card-bg;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
}

.card-interactive {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    > .card {
      &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(to top, rgba($black, $abn-opacity / 7.5) 28%, rgba($black, $abn-opacity * 1.7) 82%);
          //When hover on this element the text becomes blurry because the hover uses a transition, by using this the text will not become blurry
          backface-visibility: hidden;
      }

      &:hover:before {
          opacity: 0.9;
          transition: opacity 0.2s;
      }
    }
}

.card-block {
  padding: $card-spacer-x;
}

.card-title {
  margin-bottom: ($card-spacer-y / 4);
  margin-top: 0;
}

.card-subtitle {
    margin: 0;
    font-family: $font-family-sans-serif;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

.card {
  > .list-group:first-child {
    .list-group-item:first-child {
      border-radius: $card-border-radius $card-border-radius 0 0;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      border-radius: 0 0 $card-border-radius $card-border-radius;
    }
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    border-radius: $card-border-radius-inner $card-border-radius-inner 0 0;
  }
}

.card-footer {
  position: absolute;
  margin: 0;
  bottom: 0;
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
  @include card-inverse;
}

// Card image
.card-img {
  border-radius: 4px;

  // make responsive
  display: block;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-spacer-y;
  overflow: hidden;
  // added a border bottom so overflow hidden wil not exceed the 20px of the padding.
  border-bottom: solid $card-spacer-y rgba(0,0,0,0);
}

// Card image caps
.card-img-top {
  border-radius: $card-border-radius-inner $card-border-radius-inner 0 0;
}
.card-img-bottom {
  border-radius: 0 0 $card-border-radius-inner $card-border-radius-inner;
}

// A featured card
.card-featured {
  .card-img-overlay {

      @media (min-width: $screen-xs) {
          padding: ($card-spacer-y * 1.5);
    }
  }
}
