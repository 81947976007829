//
// i-texts
// --------------------------------------------------

// Base class
.ocf-i-text {
    display: block;
    font-size: $font-size-base;
    line-height: 1.4;
    position: relative;
    visibility: hidden;
    z-index: $zindex-i-text;

    &.in  {
        opacity: 1;
        visibility: visible;
    }

    &.top.in {
        padding: $i-text-arrow-width;
    }

    // size of close icon
    .glyphicon {
        @include square($icon-size-default);
    }
}

// Wrapper for the i-text content
.ocf-i-text-inner {
    background-color: $abn-contrast;
    border: 2px solid $popover-fallback-border-color;
    border: 2px solid $popover-border-color;
    color: $gray-darkest;
    max-width: 100%;
    padding: 15px;
    text-align: left;
    text-decoration: none;

    h3 {
        color: $gray-darkest;
        margin: 0 30px 0 0;
    }

    p {
        margin-right: 30px;
        margin-bottom: 0;
    }

    // position of close icon
    .ocf-icon-close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

// styling of close icon
.ocf-i-text .ocf-icon-close {
    margin-left: 50px;
}
