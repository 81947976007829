//
// IBAN
// --------------------------------------------------

.ocf-iban {
    display: block;
    height: 36px;

    .input-group {
        border-right: none;
    }

    .ocf-iban-input {
        border: 1px solid $gray-dark;
        border-radius: $input-border-radius;
        height: 36px;
        white-space: nowrap;
        word-wrap: normal;
    }

    .input-group-addon {
        border: none;
        display: inline;
        float: left;
        height: 34px;
        padding: 9px $grid-gutter-width/2 10px;
        width: 57px;
    }

    .ocf-iban-countrycode,
    .ocf-iban-bankcode {
        border-right: none;
        display: inline;
        float: left;
        height: 36px;
        padding: 6px 4px;
    }

    .ocf-iban-countrycode {
        padding-left: 10px;
        width: 29px;
    }

    .ocf-iban-validation {
        border: none;
        display: inline;
        float: left;
        height: 34px;
        padding: 6px 3px;
        width: 25px;
    }

    .ocf-iban-bankcode {
        width: 52px;
    }

    .ocf-iban-accountnumber {
        border: none;
        display: inline;
        float: left;
        height: 34px;
        padding: 6px 3px;
        width: 104px;
        width: calc(100% - 163px);
    }
}

.ocf-iban[disabled] .ocf-iban-input {
    background-color: $input-bg-disabled;
    border-color: $input-disabled-border;
    color: $input-disabled-color;
    .input-group-addon {
        color: $input-disabled-color;
    }
}

// input field with warning

.has-warning .ocf-iban-input {
    border: 1px solid $brand-danger;
}

.ocf-iban.input-group + .help-block {
    margin-top: 8px;
}