/**
 * Forms overrides for the theme
 */

/* set labels font-weight */

label {
    font-weight: normal;
    margin-bottom: 5px;
    color: $gray-darker;
}

.sublabel {
    color: $gray;
}

// The fieldset is given the abn-contrast to the background;

fieldset {
    border: none;
    // abn amro puts things full panel width
    margin: 0 ($grid-gutter-width / -2 ) $grid-gutter-width ($grid-gutter-width / -2 );
    background: $abn-contrast;
    padding: $grid-gutter-width ($grid-gutter-width / 2 ) $grid-gutter-width ($grid-gutter-width / 2 );

    // bigger screens just follow grid layout
    @media (min-width: $screen-sm-min) {
        margin: 0 0 $grid-gutter-width 0;
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width;
    }

    fieldset.ocf-nested-group {
        margin: 0;
        padding: 0;

        // show a legend within a fieldset as a label for radio and checkbox groups
        legend {
            @extend label;
            background: transparent;
            margin: 0 0 5px 0;
            padding: 0 10px 0px 10px;
            font-family: $font-family-base;
            font-size: $font-size-base;
            line-height: $line-height-base;

            @media (min-width: $screen-sm-min) {
                text-align: right;
            }
        }

        .ocf-legend-itext {
            display: inline-block;

            @media (min-width: $screen-sm-min) {
                display: block;
            }
        }

        .ocf-legend-itext > legend {
            width: initial;
            float: left;
            padding-left: 0;

            @media (min-width: $screen-sm-min) {
                max-width: 90%;
                max-width: calc(100% - 24px);
                width: 100%;
                padding-right: 5px;
                padding-left: 10px;
            }
        }


    }

    .form-group-last {
        margin-bottom: 0;
    }
}

fieldset.ocf-no-contrast {
    background: transparent;
}

// the legend of a fieldset is positioned visually as a title above the fieldset

legend {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-h4;
    line-height: $headings-line-height;
    color: $heading4-color;
    border: none;
}

legend,
legend + p {
    display: block;
    width: 100%;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding: 0 10px 10px 10px;
    background: $panel-bg;

    @media (min-width: $screen-sm-min) {
        margin-left: (-$grid-gutter-width);
        width: calc(100% + 40px);
        padding: 0 0 10px 0;

    }
}

legend + p {
    position: relative;
    top: -20px;
}

.form-group {
    margin-bottom: 20px;
    font-size: $font-size-medium;
}

.form-control {
    @include box-shadow(none);
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus();

    // Placeholder
    @include placeholder();

    display: inline-block;

    /* For Firefox */
    word-wrap: initial;

    overflow-wrap: initial;
    word-break: initial;
    // ie fix
    -ms-word-wrap: initial;

    width: 100%;
    height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $input-border;
    border-radius: $input-border-radius;

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        background-color: $input-bg-disabled;
        border-color: $input-disabled-border;
        opacity: 1; // iOS fix for unreadable disabled content

    }

    &[readonly] {
        cursor: text;

        &:focus {
          border-color: #E0E0E0;
        }
    }

    &[type="text"],
    &[type="number"] {
        appearance: none;
        -moz-appearance: textfield;
    }
}

.form-control-small {
    width: 50%;
}

textarea.form-control {
    resize: none;
}

/* set the width for the icon area */

.form-control-feedback {
   @include square($icon-size-default);
}

/*
    reposition the feedback region icon for the inline-form .
    If the label has multiple lines this will not be enough.
    Use form-horizontal with grid layout instead
*/

.form-inline,
.form {
    .has-feedback .form-control-feedback {
        margin-top: 15px;
        right: 8px;
    }
}

.form-horizontal  .has-feedback .form-control-small + .form-control-feedback {
    right: 52%;
    right: calc( 50% + 8px);
}

.form-inline {
    .has-feedback .form-control-feedback {
        margin-top: 8px;
    }
}

/* reposition the feedback region icon for the horizontal form
  Please be aware to put form controls in a grid with horizontal forms
*/

.form-horizontal {
    .has-feedback .form-control-feedback {
        top: 7px;
        right: ($grid-gutter-width / 2 + 9px);
    }
    .has-feedback {
        .radio .form-control-feedback {
            position: relative;
            top: 4px;
            right: 0;
            margin-right: 10px;
        }
    }
}

.form-horizontal {
    .has-feedback .form-control {
        padding-right: 10px;
    }
}

// Feedback states should only adjust border with the right border vars for that


.has-success {
    @include form-control-validation($state-success-text, $state-success-border, $state-success-bg);
}
.has-warning {
    @include form-control-validation($state-warning-text, $state-warning-border, $state-warning-bg);
}
.has-error {
    @include form-control-validation($state-danger-text, $state-danger-border, $state-danger-bg);
}

// Help text
.help-block {
    color: $abn-attention;
    display: block; // account for any element using help-block
    margin-top: 8px;
    margin-bottom: 0;
}

span.glyphicon-warning-sign + .help-block {
  margin-top: 5px;
}

.input-group input {
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
}

//adding input-group will reset the margin-top
.ocf-nested-group {
    .input-group + .help-block {
        margin-top: 0px;
    }
}

textarea + .help-block {
  margin-top: 3px;
}

.input-group > .radio:last-child,
.input-group > .checkbox:last-child {
    padding-bottom: 0px;
}

.input-group > .checkbox + .help-block{
    margin-top: 0;
}

.input-group-btn {
    padding-left: 10px;
}

.input-group-btn button.btn-default {
    min-width: 0;
}

.input-group-addon + input.form-control {
  border-left: 0;
}

.input-group-addon {
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;

  .glyphicon {
    @include square($icon-size-small);

    margin-top: 2px;
  }
}

// Fix arrows on numeric input fields
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

// input warning
.has-warning .form-control,
.has-error .form-control {
    background-color: #ffffff;
    border-color: $abn-attention;
    &:focus {
        border-color: $abn-attention;
    }
}

.input-group.addon-warning > .input-group-addon + .form-control {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
}

// addon warning
.has-warning .input-group-addon,
.has-error .input-group-addon {
  background-color: $gray-lighter;
  border-color: $abn-attention;
}

// help-block TODO --> Is this still relevant?
.has-warning .help-block,
.has-error .help-block {
    color: $abn-attention;
}

.help-block .glyphicon {
    @include square($icon-size-default);
}

// Custom class to style non-active labels which are also not part of a form
.ocf-label-static,
.ocf-label-static p {
    text-align: left;
    color: $gray;

    @media (min-width: $screen-sm-min) {
        text-align: right;
    }

}

.form-horizontal {
    .radio,
    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;

        label {
            color: $gray-darkest;
        }
    }
    .radio-inline,
    .checkbox-inline {
        margin-right: 16px;
        padding-bottom: 5px;
        margin-left: 0;
    }
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
    margin: 5px 0 0;
}

.form-inline input[type="radio"] + label,
.form-inline input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="checkbox"] + label {
    padding-left: 6px;
}

.form-group.error .glyphicon-warning-sign.form-control-feedback {
    top: 6px;
    right: 17px;
}

// add a different border to distinguish
input[disabled],
.input-group-addon.disabled,
.input-group-addon[disabled] {
    border-color: $input-disabled-border;
    color: $input-disabled-color;
    -webkit-text-fill-color: $input-disabled-color;
}

// style the disabled version of the selectbox
select.disabled {
    border: 1px solid $gray-light;
    color: $gray;
}

// style readonly versions
input[readonly],
.input-group-addon[readonly],
select[readonly],
textarea[readonly] {
    border-color: $input-disabled-border;
    color: $gray-darkest;
    -webkit-text-fill-color: $gray-darkest;
}

// remove border on focus
:focus {
    outline: none;
}

span.ocf-addon-dropdown {
    cursor: pointer;
}

select.ocf-addon-select {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    line-height: normal;
    height: 22px;
}


//
// Checkboxes
// --------------------------------------------------


.checkbox {
  padding-left: 20px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $gray;
      border-radius: 3px;
      background-color: #fff;
      margin-top: 2px;
    }

    &::after{
        display: inline-block;
        position: absolute;
        width: 11px;
        height: 11px;
        left: 0;
        top: 0;
        margin-left: -17px;
        padding-left: 8px;
        padding-top: 4px;
        font-size: 11px;
        margin-top: 5px;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;


    &:focus + label::before {
        background-color: $brand-warning;
    }

    &:checked + label::after {
        background-image: svg("ocf-icon-checkbox-check", ".primaryFill[fill]: #{$icon-additional};");
        content: " ";
        background-size: contain;
        background-repeat: no-repeat;

    }

    &:disabled + label {
      opacity: 0.65;
      cursor: default;

      &::before{
        background-color: $gray-light;
        cursor: default;
      }
    }
  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

@media (min-width: $screen-sm) {
  .form-inline .checkbox input[type="checkbox"] {
    position: absolute;
  }
}

.form-inline .checkbox {
    margin-top: 0;
    margin-bottom: 0;
}


// input warning
.has-warning .checkbox > label,
.has-error .checkbox > label {
              &::before,
              &:focus::before {
                  border-color: $abn-attention !important;
              }
}


//
// Radios
// --------------------------------------------------


.radio {
    padding-left: 20px;

    label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 5px;

        // outer circle
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 17px;
          height: 17px;
          left: 0;
          margin-left: -20px;
          border: 1px solid $gray;
          border-radius: 50%;
          background-color: #fff;
          margin-top: 2px;
        }

        // inner circle (checked)
        &::after {
          display: inline-block;
          position: absolute;
          content: " ";
          width: 7px;
          height: 7px;
          left: 5px;
          top: 5px;
          margin-left: -20px;
          border-radius: 100%;
          background-color: $abn-additional;
          opacity: 0;
          margin-top: 2px;
        }
    }

  input[type="radio"]{
    opacity: 0;
    z-index: 1;

    &:focus + label::before {
      background-color: $brand-warning;
    }

    &:checked + label::after {
        opacity: 1;
        @include scale(1, 1);
    }

    &:disabled + label {
      opacity: 0.65;
      cursor: default;

      &::before{
        cursor: default;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}


// input warning
.has-warning .radio > label,
.has-error .radio > label {
        &::before,
        &:focus::before {
            border-color: $abn-attention !important;
        }
}

//eruo field
.euro-icon {
  position: absolute;
  top: 7px;
  padding-left: 10px;
  color: $gray-darkest;
}
.euro-field {
  text-align: right;
  padding-left: 25px;
  &.static-suffix {
    padding-right: 22px !important;
  }
}
.euro-suffix {
    position: absolute;
    right: 20px;
    height: 36px;
    top: 8px;
    color: $gray-darkest;
    font-size: $font-size-base;
    &.disabled {
        color: $gray;
    }
}
.has-feedback {
    .euro-suffix {
        position: absolute;
        right: 48px;
        height: 36px;
        top: 8px;
        color: $gray-darkest;
        font-size: $font-size-base;
    }
    .euro-field.static-suffix {
        padding-right: 50px !important;
    }
}
.currency-wrapper {
    top: 1px;
    left: 11px;
    position: absolute;
    background: $gray-dark;
    padding-right: 1px;
    .btn {
        background-color: $gray-lighter;
        color: $gray-darkest;
        padding: 6px 10px 7.6px 10px;
        border: 0;
        outline: none;
        max-height: $input-height-base - 2;
        overflow: hidden;
    }
    &.disabled {
        background-color: $gray-light;
        margin-top: 0.5px;
        padding-right: 2px;
        .btn.disabled {
            opacity: 1;
            color: $gray;
            .caret {
                color: $gray;
            }
        }
    }
    .dropdown-menu li a {
        color: $gray-darker;
        background-color: $gray-lighter;
    }
}
.has-feedback .currency-wrapper {
    top: 1.25px;
    .btn {
        max-height: $input-height-base - 2.5;
        overflow: hidden;
    }
}

.ocf-btn-emphasis-container {
  width: $btn-extraextralarge;

}

.form-horizontal .has-feedback .addon-warning .form-control-feedback {
  right: 8px;
}

.addon-warning + .help-block {
    margin-top: 10px;
}

// input.form-control {
//   text-overflow: string;
//   text-redering: auto;
//   box-sizing: border-box;
//   display: inline-block;
//   overflow: hidden;
// }


// currency
.ocf-currency-symbol {
    position: absolute;
    top: 7px;
    padding-left: $grid-gutter-width / 2;
}

// percentage
.ocf-percentage-symbol {
    position: absolute;
    top: 7px;
    margin-left: -20px;
}
.ocf-form-control-percentage {
    text-align: right;
    padding: 6px 20px;
}

.ocf-amount-rounded-suffix {
    position: absolute;
    right: $grid-gutter-width;
    height: 36px;
    top: 8px;
    font-size: $font-size-medium;
}

.ocf-amount-rounded,
.ocf-amount-cents {
    text-align: right;
    padding-left: 25px;
}

.ocf-amount-rounded.static-suffix {
    padding-right: 22px !important;
}

.has-feedback .ocf-amount-rounded-suffix {
    position: absolute;
    right: 51px;
    height: 36px;
    top: 8px;
    font-size: $font-size-medium;
}

.has-feedback .ocf-amount-rounded.static-suffix {
    padding-right: 52px !important;
}

.has-feedback .ocf-amount-cents {
    padding-right: 40px !important;
}

.input-group-btn-separated {
    @extend .input-group-btn;
}

.input-group-separated {
    @extend .input-group;

    .form-control:first-child {
        border-bottom-right-radius: $input-border-radius;
        border-top-right-radius: $input-border-radius;
    }
}






// give the checkbox in a tile a decent click area when it has no label
.checkbox-label-hidden {
    input[type="checkbox"] {
        min-height: 100%;
        width: 42px;
        height: 75px;
        margin: 0px 0 0 -35px;
        z-index: 5;
        cursor: pointer;
        position: absolute;
        top: -15px;
        overflow: hidden;
    }

    // custom checkbox with a state indicator
    &.ocf-checkbox-fancy {
        margin: 0 10px 0 0;
        float: left;

        label {
            @include square($icon-size-large);
            padding: 0;
            margin: 5px 0 0 0;
            cursor: pointer;
            vertical-align: middle;

            &:before {
                content: '';
                display: block;
                position: absolute;
                box-shadow: $box-shadow;
                //border: 1px solid white;
                @include square($icon-size-default);
                background-size: contain;
                background-color: $abn-contrast;
                background-image: svg("ocf-icon-plus", ".primaryFill[fill]: #{$gray-light}; .secondaryFill[fill]: none;");
            }

            &:hover:before {
                background-image: svg("ocf-icon-plus", ".primaryFill[fill]: none; .secondaryFill[fill]: #{$icon-additional};");
            }

        }

        input[type="checkbox"] {
            margin: 0;
            visibility: hidden;
            width: 0;

            + label {
                padding: 0;
            }

            &:checked + label {
                &:before {
                    background-image: svg("ocf-icon-checkmark-fancy", ".primaryFill[fill]: #{$icon-additional};");
                }
            }
        }
    }
}

// make a checkbox as a toggle

.ocf-checkbox-toggle {
    display: inline-block;
    position: relative;
    margin-top: -6px;

    input[type="checkbox"] {
        width: 0;
        height: 0;
    }

    input[type="checkbox"] + label {
        height: 36px;
        width: 70px;
        overflow: hidden;
        padding: 0;
        border: 1px solid darken($gray-light, 5%);
        border-radius: 18px;
        background: $gray-light;
        cursor: pointer;
    }

    input[type="checkbox"]:focus + label {
        border: 1px dashed transparent;
    }

    label:before {
        display: block;
        content: '';
        width: 36px;
        height: 36px;
        border: 1px solid darken($gray-light, 5% );
        border-radius: 18px;
        background: $abn-contrast;
        margin-left: -1px;
        margin-top: -1px;
        transition-property: background, margin-left, border-color;
        transition-duration: 0ms, 200ms, 200ms;
        transition-delay: 0ms 10ms, 0ms;
        z-index: 2;
    }

    label:after {
        display: block;
        position: absolute;
        content: '';
        @include square($icon-size-medium);
        top: 6px;
        left: 40px;
        background-image: svg("ocf-icon-clear", ".primaryFill[fill]: transparent; .secondaryFill[fill]: #{$icon-neutral};");
        transition-property: background;
        transition-duration: 10ms;
        transition-delay: 0;
        z-index: 1;
    }

    input[type="checkbox"]:checked + label {
        background: $abn-additional;
        border-color: $abn-additional;

        &:before {
            margin-left: 34px;
            border-color: $abn-additional;
        }

        &:after {
            left: 12px;
            background-image: svg("ocf-icon-checkmark-fancy", ".primaryFill[fill]: #{$abn-contrast};");
        }
    }


    input[type="checkbox"]:disabled + label {
        background: $gray-lighter;
        border-color: $gray-lighter;

        &:after {
            opacity: .5;
        }

        &:before {
            background: $gray-lighter;
        }
    }

    input[type="checkbox"]:disabled:checked + label {
        background: rgba($abn-additional, .5);
        border-color: $gray-lighter;

        &:before {
            border: $gray-lighter;
        }

        &:after {
            opacity: 1;
        }
    }


}

.ocf-checkbox-toggle + .help-block {
    margin-top: -6px;
    margin-bottom: 0;
}
