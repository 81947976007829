/*solution for combining multiple fields */

.ocf-group {
    display: block;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-right: -5px;
}

.form-group.form-group-separated::after {
    display: block;
    content: '';
    height: 10px;
    border-bottom: 1px solid $gray-light;

    @media (min-width: $screen-md-min) {
        margin: 0 -10px;
    }
}

.ocf-group-fields {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    flex: 1;
    
    width: 80%;
    flex-basis: 80%;

    @media (min-width: $screen-md-min) {
        max-width: auto;
    }
    float: left;
    margin-right: 5px;
}

.ocf-group .ocf-group-item {
    display: table-cell;
    display: flex;
    flex: 1;
    padding: 0 5px 5px 0;
    width: auto;

}

.ocf-group-item.ocf-group-item-full {
    display: block;
    display: flex;
    flex-basis: 100%;
    min-width: 100%;
}

.ocf-group-item.ocf-group-item-large {
    flex-basis: 50%;
    min-width: 50%;
    grow: 2;
}

.ocf-group-item.ocf-group-item-small {
    flex-basis: 90px;
    min-width: 90px;
    flex-grow: 0;
}

.ocf-group-item.ocf-group-item-xsmall {
    flex-basis: 75px;
    min-width: 75px;
    flex-grow: 0;
}

.ocf-group-control {
    flex-grow: 0;
    margin-right: 5px;
}

.ocf-group-control > .btn.btn-default {
    min-width: 0;
    float: right;
}

.ocf-group .help-block {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
}
