div.datepicker {    
    
    .dropdown-menu {
        box-shadow: none;
        margin-top: 2px;
        left: auto !important;
        right:0;
    }

    table {
        border-spacing: 0;
        width: $datepicker-width;
    }   

    table[ng-switch-when="month"],
    table[ng-switch-when="year"] {

        tr:nth-last-of-type(1) td {
            //border-bottom: 1px solid darken($gray-light, 10%);
        }



    }    

    table[ng-switch-when="day"] {
        thead 
        { 
            tr {
                th:nth-child(2) button {
                    border: none;                
                }
            }

            tr:nth-child(1){
                border-bottom: 1px solid darken($gray-light, 10%);
            }
        }
    }

    table[ng-switch-when="year"]
    {

        thead {
            border-bottom: 1px solid darken($gray-light, 10%);
            tr {
                th:nth-child(2) button {
                    border: none;                
                }
            }
        }

        thead tr th {
            background-color:#fff;
        }

        tbody tr {
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            td{
                width: 50px;
            }
        }



    } 

    table[ng-switch-when="month"] {

        thead {
            border-bottom: 1px solid darken($gray-light, 10%);
             th:nth-child(2) button {
                padding-left: 20px;
            }

        }
        
        th {
            background-color: #ffffff;           
        }

        th button[role="heading"] {
            border: none;
        }

        button[disabled="disabled"] span {
            background: lighten($gray-light, 5%);
        }
    }

    th {
        background-color: $gray-light;
        text-transform: lowercase;
        width: 37px;
    }

    th > button.btn-default.pull-left{
        border-right: none;
    }

    th > button.btn-default.pull-right{
        border-left: none;
    }

    th > button.btn-default {
       height: 37px;
       min-width: 0;
       width: 37px;

       strong {
            color: $abn-action;
            text-decoration: underline;
            font-weight: normal;
            font-size: 16px;
       }       
    }

    th:nth-of-type(2) > button {
        background-color: $abn-contrast;
    }

    thead tr:nth-of-type(2) th {
        background-color: $abn-contrast;
        height: $datepicker-row-height;
    }

    thead button.btn-sm {
        padding: 0 0;
    }

    tr {
        border: none;
        margin: 0 0;
        padding: 0 0;
    }

    td {
        background: lighten($gray-light, 5%);
        margin: 0 0;
        padding: 0 0;
        font-size: 14px;

        &.h6 {
            background-color: $abn-contrast;
        }

        em {
            color: $gray;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
        }
    } 

    td > button.btn-default {
        background-color: $gray-light;
        border: none;
        min-width: 0;
        padding: 0 0;

        &.active span {
            background-color: $abn-additional;
            color: $abn-contrast;
            border-color: $abn-additional;
            &:hover {
                background-color: $abn-highlight;
                color: $gray-darker;
                border-color: darken($gray-light, 10%);
            }
        }

        span {
            background-color: $abn-contrast;
            border: 1px solid darken($gray-light, 10%);
            border-right: none;           
            border-top: none;
            display: block;
            height: $datepicker-row-height;
            padding: 2px 2px;

            &:hover {
                background-color: $abn-highlight;
            }
        }

        span.text-muted {
            background-color: $gray-light;
            background: lighten($gray-light, 5%);
            border: none;
            color: lighten($gray-light, 5%);
            display: block;
        }
    }

    td > button.btn[disabled] {
        opacity: 1;
    }

    small {
        @extend .h5;
    }

    .active{
        @extend .label-success;
    }

    .dropdown-menu{
        border: none;
        @include box-shadow($box-shadow);

        .btn-group button {
            background-color: $gray-light;
            border: 1px solid darken($gray-light, 10%);
            color: $gray-darker;
            margin-bottom: 8px;

            &:hover {
                background: darken($gray-lightest, 20%);
            }
        }              
    }
    
    button[ng-click^="select(null)"] {
        display: none;
    }

    button[ng-click^="close()"] {
        display: none;
    }

    td button[disabled^="disabled"] span{
        background: lighten($gray-light, 5%);
        border: none;
        color: $gray;
    }

    li[ng-if^="showButtonBar"]{
        text-align: center;
    }
    
}

fieldset[disabled] .form-control{
    border-color: $input-disabled-border;
}
