//
// Accordion
// --------------------------------------------------

fieldset {
    .aab-accordion {
        .panel-group {
            margin: 0;
        }
    }
}

.aab-accordion {

    .panel-group {
        border-top: 1px solid $gray-light;
        margin: $grid-gutter-width 0 0;
    }

    .panel {
        background: transparent !important;
        border-width: 0;
        border-color: $gray-light;
        border-bottom-width: 1px;
        box-shadow: none !important;

        + .panel {
            margin-top: 0;
        }
    }

    .panel-heading {
        background: transparent;
        min-height: auto;
        padding: 0;

        span.glyphicon {
            margin-left: 9px;
            margin-right: 5px;
            margin-top: 4px;
        }
    }

    .panel-title {
        color: $abn-additional;
        font-size: $font-size-h3;
        text-align: left;
        margin: 0;
        width: 100%;

        a {
            display: block;
            text-decoration: none;
            cursor: pointer;

            .panel-title-wrapper{
                padding: 10px 10px 10px 0;
                word-wrap: normal;

                //Truncatin for long titles. Max Width can be sent as a parameter
                @include text-truncate (100%);
            }

            &:hover {
                background-color: darken($btn-default-bg, $btn-hover-darken-percentage);
                min-height: 51px;
            }
            &:active{
                background-color: darken($btn-default-bg, $btn-active-darken-percentage);
            }
        }
    }

    .panel-body {
        border: none !important;
        font-size: $font-size-base;
        color: $text-color;
        line-height: $line-height-computed;
        padding: 10px 10px 20px 40px;
    }

    span.glyphicon.disabled {
        opacity: 0.3;
    }

}
