/**
 * POCA Core CSS, Bootstrap based Theme
 */

//	All images use the assetPath to have more control over the styling
$assetPath : "./images";

// Start with importing the themed version of Bootstrap
// Add all your SCSS variables to Bootstraps _theme_variables.scss and reuse theme as much as possible

@import "_theme_bootstrap";
// Add non-bootstrap based parts


// Add non-Bootstrap components and mixins first

@import "_animations"; // animations lib

@import "_stepcounter";


// Add non-Bootstrap sections

@import "_header";  // the header section
@import "_footer";  // the footer and its content
@import "_doormat"; // the doormat and its content

// all the icons are loaded in a seperate file, because IE9 can't handle it otherwise
@import url("icons.css");
