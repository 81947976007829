//
// Logout widget
//

.ocf-logout-widget {
    margin-bottom: $grid-gutter-width;
    @media (max-width: $screen-sm - 1px) {
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
    }


    .ocf-logout-widget-btn {
        float: right;
        margin-left: 10px;
    }

    .ocf-login-status {
        color: $gray-dark;
        display: inline-block;
        float: right;
        font-size: $font-size-small;
        line-height: 34px;
    }
}
