// Buttons

// Remove underline from buttons
a.btn {
    text-decoration: none;
}

// set a min width for normal buttons, not for icons
.btn-default,
.btn-cta,
.btn-primary,
.btn.ocf-dropdown {
    min-width: 70px;
    &.dropdown-toggle {
        // reset this for dropdowns
        min-width: auto;
    }
    &.btn-dropdown,
    &.ocf-dropdown-narrow {
        // reset this for splitbutton and narrow dropdown buttons
        min-width: 30px;
    }
}

.btn-link.ocf-dropdown {
    text-decoration: none;

    &:hover,
    &:active,
    &.active,
    &:focus {
        text-decoration: none;
        color: $abn-action;
        border: 1px solid $gray-light;
    }
}
 .btn.btn-smallest-width {
          min-width: auto;
 }

.btn-link.btn-smallest-width + .dropdown-menu {

    width: 100%;

    a {
        min-width: auto;
        padding-right: $padding-base-horizontal;
        padding-left: $padding-base-horizontal;
    }
}

.btn-cta {
    @include button-variant($btn-cta-color, $btn-cta-bg, $btn-cta-border);
}

.btn {
    &:active,
    &.active {
        @include box-shadow(none);
    }
}

// remove outline on buttons
button,
.btn:focus,
.btn:active,
.btn:active:focus,
.btn.active:focus{
    outline: none;
}

// custom back button, styles the ::before with css borders to create an arrow shaped button
.ocf-btn-back {

    @media (min-width: $screen-md-min) {
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        border-radius: 1px 0 0 1px;
        position: relative;
        margin-left: 18px;
        padding: 6px 10px;
        //min-width override of btn-default
        min-width: $icon-size-large;


        &:after,
        &:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            z-index: 300 !important;
            background-color: transparent;

        }

        &:after {
            border-right-color: $btn-default-bg;
            border-width: 17px;
            margin-top: -17px;
        }

        &:before {
            border-color: transparent;
            border-right-color: $btn-default-border;
            border-width: 18.5px;
            margin-top: -18.5px;
            border-radius: 3px;
        }

        &:hover,
        &:focus {

            &:after {
                border-right-color: darken($btn-default-bg, $btn-hover-darken-percentage);
            }

            &:before {
                border-right-color: darken($btn-default-border, $btn-hover-darken-percentage);
            }
        }

        &:active {

            &:after {
                border-right-color: darken($btn-default-bg, $btn-active-darken-percentage);
            }

            &:before {
                border-right-color: darken($btn-default-border, $btn-active-darken-percentage);
            }
        }

        &:disabled,
        &.disabled {

            &:after {
                border-right-color: $btn-default-bg;
            }

            &:before {
                border-right-color: $btn-default-border;
            }
        }
    }

    @media (max-width: $screen-sm-max) { // custom back button on mobile
        @include button-variant(transparent, transparent, transparent);
        @include square($icon-size-large);

        background-image: svg("ocf-icon-back", ".primaryFill[fill]: #{$icon-neutral};");
        background-size: $icon-size-small;
        background-repeat: no-repeat;
        background-position: center;
        //min-width override of btn-default
        min-width: $icon-size-large;
        margin-left: -8px;
        display: inline-block;
        z-index: 100;

        &,
        &:hover,
        &:focus {
            @include box-shadow(none);
            color: transparent;
            background-image: svg("ocf-icon-back", ".primaryFill[fill]: #{$icon-neutral};");
            outline: none;
        }
    }
}

.ocf-btn-cancel {

    @media (max-width: $screen-sm-max) { // custom back button on mobile
        @include button-variant(transparent, transparent, transparent);
        @include square($icon-size-large);

        //min-width override of btn-default
        min-width: $icon-size-large;

        background-image: svg("ocf-icon-cancel", ".primaryFill[fill]: #{$icon-neutral};");
        background-size: $icon-size-small;
        background-repeat: no-repeat;
        background-position: center;

        display: inline-block;
        overflow: hidden;

        &,
        &:hover,
        &:focus {
            @include box-shadow(none);
            color: transparent;
            background-image: svg("ocf-icon-cancel", ".primaryFill[fill]: #{$icon-neutral};");
            outline: none;
        }
    }
}

// primary buttons within a form or widget should always align at the right site
form .btn-primary,
.panel .btn-primary {
    float: right;
}

// Added buttons that are only consist of graphical elements
// extend with the .glyphicon class and the icon classname of the desired icon
// for instance: "ocf-btn-icon glyphicon glyphicon-info-sign"

.ocf-btn-icon {
    @include square($icon-size-medium);
    padding: 0;
    background-color: transparent;
    appearance: icon;

    &,
    &:hover,
    &:focus {
        background-color: transparent;
        border: none;
        outline: none;
    }
}

.ocf-btn-icon.glyphicon-pencil,
.ocf-btn-icon.glyphicon-icon-close {
    float: right;
    margin: -$grid-gutter-width/2 $grid-gutter-width /2 0 0;
}

// button with icon and text

.btn.ocf-button-icon {
    padding-top: 6px;
    padding-bottom: 6px;

    .glyphicon {
        margin-right: 10px;
    }
}

.btn.ocf-button-icon-alt {
    padding-top: 6px;
    padding-bottom: 6px;

    .glyphicon {
        margin-left: 5px;
    }
}

.btn.ocf-button-icon-right {
    text-align: left;

    .glyphicon {
        float: right;
    }

    .glyphicon.ocf-icon-small,
    .glyphicon.ocf-icon-add {
        margin-top: 3px;
        margin-left: 10px;
    }
    .glyphicon.ocf-icon-add {
        @include square($icon-size-small);
    }
}

// this class is used to get truncation to work
.ocf-button-text-with-icon {
    @include text-truncate (100%);
    display: block;
    padding-right: 5px;
}


.btn-group {
    &.btn-group-justified {
        display: table;
    }
}

// Buttongroup justified, buttons with text and icon, icon on top:

.btn-group-justified.ocf-btn-group-icons {

    border-radius: 0 0 8px 8px;
    height: 63px;

    .btn-default {
        color: $gray-darker;
        font-size: 12px;
        height: 63px;
    }
}

// Buttongroup alignment, buttons with alignment right:

.btn-group-aligned-right {
    float: right;
}

aab-button-bar[alignment="right"] {
   @extend .clearfix;
}


.btn-group .ocf-icon-only {
    margin-right: 10px;
}

.btn-group .btn-group:last-of-type .ocf-icon-only {
    margin-right: 0;
}

//Sizing of icon only buttons

.ocf-icon-only {
    min-width: auto;
    width: 55px;
    height: 45px;
}

.btn.ocf-button-icon.ocf-icon-only .glyphicon {
    margin-right: 0;
}

// Alternative Radiobuttons
.ocf-btn-alt {
    @include button-variant($btn-alt-color, $btn-alt-bg, $btn-alt-border);
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    border: 1px solid $btn-alt-border;
    background-color: $btn-alt-bg-inactive;
    color: $btn-alt-color;

    &.inactive {
        border: 1px solid $btn-alt-border;
        background-color: $btn-alt-bg-inactive;
    }

    &.active {
        border: 1px solid $btn-alt-border-active;
        background-color: $btn-alt-bg;
    }

    &:focus,
    &:hover {
        border: 1px solid $abn-additional;
        background-color: $btn-alt-bg;
    }

    &:disabled,
    &.disabled {
        opacity: $abn-opacity;
    }

    span.ocf-btn-alt-icon {
        margin-top: 5px;
    }

    //margin fix with an icon
    span + span.ocf-btn-alt-text {
        float: left;
    }

    &:last-child {
       margin-right: 0;
    }
}

.ocf-btn-alt-default {
    min-height: 55px;
    line-height: 40px;
    display: block;
    width: 139px;

    .glyphicon {
        float: left;
    }

    .ocf-btn-txt {
        display: block;
        text-align: center;
        word-wrap: break-word;
    }
}

.btn-group > .ocf-btn-alt-default {
    width: 100%;
    margin-left: 0;
    margin-top: -1px;

    &:first-child {
        margin-left: -1px;
    }
    
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .ocf-btn-alt-default {
        width: 139px;
        margin-top: 0;
        margin-left: -1px;
    }
}

.ocf-btn-alt-large {
    // padding of 14px to make 3 ocf-btn-alt-large stand next to eachother in a btn-group on xxs viewport
    padding: 14px;
    min-height: auto;
    width: auto;
}

.ocf-btn-alt-emphasis {
    @include square($btn-extraextralarge);
    padding: ($grid-gutter-width / 2) ($grid-gutter-width / 4);
    background-color: $btn-alt-bg;
}

.ocf-btn-alt-emphasis-with-text {
    span.glyphicon {
        margin-top: -5px;
    }

    span + span {
        text-align: center;
        line-height: 17px;
        color: $gray-darkest;
        float: left;
        white-space: normal;
        font-size: $font-size-alt;
        margin-top: 3px;
        width: 100%;
    }
}

.btn-group > .ocf-btn-alt-emphasis-with-text {
    margin-right: 0;
}

.btn-group-alt,
.ocf-btn-navigation {
    .ocf-btn-txt {
        margin-top: 3px;
        font-size: $font-size-smallest;


        @media (min-width: $screen-xs) {
            font-size: $font-size-small;
        }

        @media (min-width: $screen-sm) {
            font-size: $font-size-base;
            display: inline-block;
            text-align: left;
            margin-top: 0;
        }
    }

    &,
    .btn {
        padding: inherit 10px;
        min-width: initial;

        @media (max-width: $screen-xs - 1px) {
            padding: 10px 0px;
        }

        @media (max-width: $screen-sm - 1px) {
            height: 60px;

            &.btn.ocf-button-icon .glyphicon {
                margin-right: 0px;
            }
        }
    }

    .btn.active,
    .btn:hover
    &.hover,
    &.active {
        //color: $brand-primary;
    }
}

.ocf-btn-alt-emphasis-with-text ~ .ocf-list {
    margin-top: 25px;
}

.ocf-btn-txt {
    display: block;
    text-align: center;
    word-wrap: break-word;
}

// small button
.btn-sm {
    font-size: $font-size-alt;
    //height: $btn-sm-height;
    padding-top: 3px;
    padding-bottom: 2px;
}

// Blocklevel buttons
.ocf-primary-switch {

    .btn {
        @media (max-width: $screen-xs - 1) {
            float: left;
            margin-top: 20px;
            min-width: 100%;
            width: 100%;
        }
    }

    .btn:first-child {
        margin-top: 0;
    }
}

.ocf-btn-block-switch {
    @media (max-width: $screen-xs - 1) {
        min-width: 100%;
        width: 100%;
    }
}

.btn-link {
    text-decoration: underline;
    padding-left: 0px;
}
