.step-counter {

  li {
    margin-right: 1em;
    .step-title {
      display: none;
    }
  }
  li.active {
    .step-title {
      display: inline-block;
    }
  }
  li + li {
	margin-left: 0px; //undo the margin that .nav-pills adds to li+li
  }
}