/**
 * ABN AMRO Update Loader
 */


// update loader

.ocf-update-loader {
	color: $abn-contrast;
	background-color: rgba($gray-darkest, .9);
	opacity: 1;
    
    border-radius: 1px;

	.spinner {
		margin-left: #{$grid-gutter-width};
	}

	width: calc(100% - #{$grid-gutter-width});
	max-width: calc( #{$screen-xs} - #{$grid-gutter-width});

    @media (min-width: $screen-md-min) {
     	margin-top: 100px;
  	}

    @media (min-width: $screen-xs-min + $grid-gutter-width) {
        float: none;
        left: calc( 50% - #{$screen-xs/2 - $grid-gutter-width/2} );
    }

    @media(max-width: $screen-xs-min - 1px) {
        right: auto;
    }

	@media(max-width: $screen-sm-min - 1px) {
		margin-left: -10px;
	}

	z-index: $zindex-popover;
	position: fixed;
	top: 0;
	animation: fadeIn 1s ease-in;
	margin: 0;
	margin-top: 50px;

	.glyphicon {
		margin-left: #{$grid-gutter-width};
		margin-top: 14px;
		float: left;
	}
}
.ocf-update-loader > span[data-us-spinner] + h3 {
	margin-left: 50px;
}

.ocf-update-loader h3 {
	color: $abn-contrast;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 50px;
}

// success message

.ocf-update-loader {
	border-spacing: ($grid-gutter-width) 0;
    display: table;
}

.ocf-update-loader > .glyphicon-ok-circle {
	display: table-cell;
	float: none;
    vertical-align: middle;
}

.ocf-update-loader > .glyphicon-ok-circle:not(.ng-hide) ~ h3 {
    margin: ($grid-gutter-width) 0 ($grid-gutter-width) ($grid-gutter-width / -2 );
    padding-right: 0;
}