//
// Panels
// --------------------------------------------------

// The heading in the panel consists of 3 sections, max 2 buttons, and a title centered on x and y.
// $ocf-panel-button-width-max reserves the spaces for the 2 buttons, in the space that rests the title is displayed

.panel {
    h3, .h3, h4, .h4 {
        margin: 0 0 $grid-gutter-width;
    }
}

.panel-heading {
    @include clearfix();
    position: relative;
    height: 50px;
    max-height: 50px;
    padding: $grid-gutter-width/2 $grid-gutter-width/2 2px;
    width: 100%;
    
    .btn,
    .btn-group {
        position: absolute;
        top: 10px;
        max-width: 44px;
        right: 10px;

        @media (min-width: $screen-sm) {          
            right: 20px;
        }

        @media (min-width: $screen-md) {
            max-width: $ocf-panel-button-width-max;
            top: 6px;
        }
    }
 
    .btn-group {
        max-width: 36px;
        top: 6px;

        .btn-link.ocf-dropdown,
        .btn.ocf-dropdown {
            color: $gray-darkest;
            float: right;
            max-width: $ocf-panel-button-width-max;
            min-width: $icon-size-default;
            padding: 6px;
            top: 0;
            right: auto;
            position: relative;


            @media (min-width: $screen-sm) {
                position: relative;
                top: auto;
                right: auto;
            }

            span.glyphicon {
                margin-right: 0;
            }
        }
        @media (min-width: $screen-sm) {
            max-width: $ocf-panel-button-width-max;
        }
    }
    
    .btn.ocf-btn-back {
        left: 10px;
        float: left;
        right: auto;
        top: 9px;
        position: absolute;
        
        @media (min-width: $screen-sm) {
           left: 20px;
           right: auto;
        }

        @media (min-width: $screen-md) {
            top: 6px;
        }
    }

    h2.panel-title {
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
        max-width: ($screen-xxs - 90px);
        max-width: calc(100% - 90px);
        margin: 0 44px auto 34px;
        font-size: $ocf-panel-title-small;
        line-height: 1;

        @media (min-width: $screen-xs) {
            max-width: ($screen-xs - 90px);
            max-width: calc(100% - 90px);
            font-size: $ocf-panel-title-medium;
        }

        @media (min-width: $screen-sm) {
            margin: 0 ($ocf-panel-button-width-max + 10px) 0 ($ocf-panel-button-width-max );
            max-width: #{($screen-md - (2 * ( $ocf-panel-button-width-max + 10px)))};
            max-width: calc(100% - #{(2 * ( $ocf-panel-button-width-max + 10px))});
        }

        @media (min-width: $screen-md) {
            margin: 0 ($ocf-panel-button-width-max + 10px);
            max-width: #{($screen-md - (2 * ( $ocf-panel-button-width-max + 10px)))};
            max-width: calc(100% - #{(2 * ( $ocf-panel-button-width-max + 10px))});
        }
    }

    .dropdown-menu {
        float: right;
        position: relative;
        max-width: $dropdown-list-max-width;
        overflow: hidden;
        right: 20px;
    }

    .dropdown-menu.dropdown-menu-right {
        left: 0;
    }
}

.panel-body {
    padding: $grid-gutter-width ($grid-gutter-width/2);

    .alert-warning {
        margin-top: -19px;
    }

    .alert-warning:first-of-type {
        margin-top: -($grid-gutter-width);
    }

    @media (min-width: $screen-sm-min) {
        padding: $grid-gutter-width;
    }
}
