.ocf-businesscard {
   
    @media (max-width: $screen-sm - 1px) {
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
    }


    .ocf-login-status {
        float: none !important;
        line-height: inherit;
        margin-top: 11px;
        overflow: hidden;
        word-wrap: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    .ocf-logout-widget-btn {
        top: 0;
        position: relative;
        margin-bottom: 20px;

        @media (max-width: $screen-sm - 1px) {
            span.glyphicon {
                display: none;
            }
        }
    }

    h5 {
        overflow: hidden;
        word-wrap: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 5px;
        margin: 0;
    }

    .ocf-businesscard-info {
        width: 100%;
    }

    .ocf-icon-personal-image-placeholder {
        margin-bottom: 10px;
    }

    .btn.ocf-button-icon .glyphicon {
        @media (max-width: $screen-sm - 1px) {
            margin-right: 0;
        }
    }
}
