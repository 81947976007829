//
// Wells
// --------------------------------------------------

.well {
    padding: 20px 10px;
    border: none;
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
    @include box-shadow(none);

	@media (min-width: $screen-sm-min) {
		padding: 20px;
		margin-left: 0;
		margin-right: 0;
	}

    p .glyphicon {
        margin-right: 10px;
    }

    .ocf-btn-icon {
        margin-right: 0;
    }

    &.well-sm {
        padding: 10px;
        margin: 0 0 20px 0;
    }
    .ocf-well-title {
        border-bottom: 1px solid $gray-light;
        margin-bottom: 20px;
        @extend .clearfix;

        @media (min-width: $screen-sm-min) {
            h5,
            .h5 {
                float: right;
                margin: 4px 0 20px 10px;
            }

            h3,
            .h3 {
                display: inline-block;
            }
        }
    }
}

.well > p:last-of-type {
    margin: 0;
}

.well-warning,
.ocf-well-warning {
    background-color: $state-warning-bg;
}

.ocf-well-notification {
    margin-bottom: 1px;
    padding-top: 10px;
    padding-bottom: 10px;

    .ocf-icon-undo {
        float: right;
        margin-right: 10px;
    }
}


.well-interactive,
.ocf-well-interactive {
    .ocf-icon-undo {
            background: svg("ocf-icon-undo", ".primaryFill[fill]: #{$icon-neutral};");
    }

    &:hover {
        background-color: $abn-highlight;
        cursor: pointer;

        .glyphicon-pencil {
            background: svg("ocf-icon-pencil", ".primaryFill[fill]: #{$icon-additional};");
        }

        .ocf-icon-undo {
            background: svg("ocf-icon-undo", ".primaryFill[fill]: #{$icon-additional};");
        }

    }

    &:active {
        background-color: darken($abn-highlight, $btn-hover-darken-percentage);
    }
}

.well-split-image{
    @extend .well;
    padding-bottom: 0px;
}