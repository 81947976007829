// When true, asset path helpers are used, otherwise regular url() is used
// When there no function is defined, `fn('')` is parsed as string that equals the right hand side
// NB: in Sass 3.3 there is a native function: function-exists(twbs-font-path)
$bootstrap-sass-asset-helper: (twbs-font-path("") != unquote('twbs-font-path("")')) !default;

//
// Variables
// --------------------------------------------------


//== Bank wide theme
//
// Brand colors for use across Bootstrap.

$brand-primary:         #005E5D !default;
$abn-additional:        #608e28 !default;
$abn-action:            #009286 !default;
$abn-primary-dark:      #004c4c !default;
$abn-accent:            #ffd200 !default;
$abn-call-to-action:    #00abe9 !default; // call to action
$abn-attention:         #ff6600 !default; // used for hover or attention (like warning)

//== Gray Colors
//
// Gray colors for use across Bootstrap.

$black:                 #000000 !default;
$gray-darkest:          #222222 !default;
$gray-darker:           #333333 !default;
$gray-dark:             #666666 !default;
$gray:                  #888888 !default;
$gray-light:            #E0E0E0 !default;
$gray-lighter:          #F5F5F5 !default;
$abn-contrast:          #FFFFFF !default;
$gray-lightest:         #F9F9F9 !default;


//== Semantic
//
// Semantic colors for use across Bootstrap.

$brand-success:         #608e28 !default;
$brand-info:            #999999 !default;
$brand-warning:         #ffffcc !default;
$brand-danger:          #ff6600 !default; //not used (or same as warning)
$abn-highlight:         #e5ffff !default;
$abn-notification:      #E7F9FF !default;
$abn-successback:       #E9F6D9 !default;
$abn-warningback:       #FFF0E6 !default;
$abn-alertback:         #FFF0EE !default;

//positive and negative value reference
$ocf-negative:          #D21B02 !default;
$ocf-positive:          #63C024 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #f9f9f9 !default;
//** Global text color on `<body>`.
$text-color:            $gray-darkest !default;

//** Global textual link color.
$link-color:            $abn-action !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      $abn-attention !default;
//** Box-shadow
$box-shadow:            0px 0px 7px rgba(0, 0, 0, 0.2) !default;
$box-shadow-active:     0px 0px 10px rgba(0, 0, 0, 0.5) !default;

//** Text-shadow
$text-shadow:           1px 1px 2px rgba(0, 0, 0, 0.2) !default;


$abn-opacity:           0.3 !default;

//## Notification Block Close icon colors
$abn-info:                   #00AAE8;
$abn-warning-fill:           $brand-danger;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Roboto', Arial, Helvetica, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$paragraph-color:         $gray-darkest !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.375)) !default; // ~22px
$font-size-medium:        16px !default; //form elements
$font-size-small:         12px !default;
$font-size-smallest:      10px !default;
$font-size-alt:           14px !default; //for instance; tables

$font-size-h1:            28px !default;
$font-size-h2:            26px !default;
$font-size-h3:            20px !default;
$font-size-h4:            18px !default;

//TODO: needs to be specified first; now using bootstrap default
$font-size-h6:            12px !default;


//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.375 !default; // 22/16
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~22px

//** By default, this inherits from the `<body>`.
$headings-font-family:    "Roboto Condensed", Arial narrow, sans-serif !default;
$headings-font-weight:    400 !default;
$headings-line-height:    1.214 !default; //(34/28) (desired lineheight / font size)
$headings-color:          $brand-primary !default;
$heading2-color:          $abn-additional !default;
$heading3-color:          $abn-additional !default;
$heading4-color:          $abn-additional !default;
$heading5-color:          $abn-additional !default;
$heading6-color:          $gray-dark !default;

//== Iconography
//
//## Colors used for icons
$icon-additional : $abn-additional !default; // can be used for primary colors or hover colors

$icon-neutral:             #777777 !default; // default or neutral state
$icon-neutral-dark:        #333333 !default;
$icon-neutral-light:       #E0E0E0 !default;
$icon-neutral-soft:        #999999 !default;
$icon-neutral-softer:      #cccccc !default;

$icon-contrast:            $abn-contrast !default; // mostly used on dark background
$icon-success:             #608E28 !default; // mostly used on dark background
$icon-attention:           $abn-attention !default; // for hovers or warnings
$icon-action:              $abn-action !default; // for hovers or warnings

$icon-bright:              #FFD200 !default; // sunny color
$icon-bright-dark:         #F1B328 !default; // mostly used for hover state on bright color



//== Components
//

$padding-base-horizontal:   10px !default;

$padding-large-horizontal:  15px !default;


$line-height-large:         $line-height-base !default;
$line-height-small:         $line-height-base !default;


$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $abn-contrast !default;


//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          5px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         6px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
$table-color:                   $gray-darkest !default;
//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            10px !default;


//** Default background color used for all tables.
$table-bg:                      $abn-contrast !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               $body-bg !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                $abn-highlight !default;

//** Border color for table and cell borders.
$table-border-color:             $gray-light !default;

//== Buttons
//

$btn-default-color:              $gray-darkest;
$btn-default-bg:                 $gray-light !default;
$btn-default-border:             darken($btn-default-bg, 5%) !default;

$btn-primary-color:              $gray-darkest;
$btn-primary-bg:                 $abn-accent !default;

$btn-cta-color:                  $abn-contrast !default; //call to action
$btn-cta-bg:                     $abn-call-to-action !default;
$btn-cta-border:                 darken($btn-cta-bg, 5%) !default;

$btn-success-color:              $abn-contrast !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 $abn-contrast !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              $abn-contrast !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $brand-warning !default;

$btn-danger-color:               $abn-contrast !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-alt-color:                 $gray-darkest !default;
$btn-alt-bg:                    $abn-contrast !default;
$btn-alt-border:                $gray-light !default;
$btn-alt-bg-inactive:           $gray-lighter !default;
$btn-alt-border-active:         $abn-additional !default;

$btn-hover-darken-percentage:    5% !default;
$btn-active-darken-percentage:   10% !default;

//== Forms
//
//##

//** `<input>` background color
$input-bg:                       $abn-contrast !default;
//** `<input disabled>` background color
$input-bg-disabled:              lighten($gray-light, 8%) !default;
$input-disabled-border:          $gray-light !default;
$input-disabled-color:           $gray !default;
//** `<input>` focus background color
$input-bg-focus:                 #ffffcc !default;


//** Text color for `<input>`s
$input-color:                    $gray-darkest !default;
//** `<input>` border color
$input-border:                   $gray-dark !default;
//** `<input>` border radius
$input-border-radius:            2px;
//** Border color for inputs on focus
$input-border-focus:             $gray-dark !default;

//** Placeholder text color
$input-color-placeholder:        lighten($gray, 8%) !default;


//== Dropdowns
//
//## Dropdown menu container and contents.
$dropdown-list-max-width:       300px !default;

//** Background for the dropdown menu.
$dropdown-bg:                   $abn-contrast !default;
$zindex-dropdown:               99 !default;
$zindex-i-text:                 1070 !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.


// Extra Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
// Add a breakpoint which covers 0..479px

$screen-xxs: 320px !default;
$screen-xxs-min: $screen-xxs !default;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
// PoCa increased the width to 1400px
$screen-lg:                  1400px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
// custom extra xxs

$screen-xxs-max:             ($screen-xs-min - 1) !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         20px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             ((730px + $grid-gutter-width)) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            ((950px + $grid-gutter-width)) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      ((1300px + $grid-gutter-width)) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


//== Navbar
//
//##


// Basics of a navbar
$navbar-height:                    30px !default;
$navbar-margin-bottom:             0 !default;
//$line-height-computed !default;
$navbar-border-radius:             0!default;
$navbar-padding-horizontal:        0!default;
$navbar-padding-vertical:          0!default;


$navbar-default-color:             #ff0000 !default;
$navbar-default-bg:                $abn-action !default;
$navbar-default-border:            none !default;

// Navbar links
$navbar-default-link-color:                $abn-contrast !default;
$navbar-default-link-hover-color:          $abn-contrast !default;
$navbar-default-link-hover-bg:             $brand-primary !default;
$navbar-default-link-active-color:         $abn-contrast !default;
$navbar-default-link-active-bg:            transparent !default;


$navbar-inverse-link-hover-color:           $abn-contrast !default;


$navbar-inverse-brand-hover-color:          $abn-contrast !default;


// Inverted navbar toggle

$navbar-inverse-toggle-icon-bar-bg:         $abn-contrast !default;

//== Navs
//
//##

$nav-open-link-hover-color:                 $abn-contrast !default;

//
//== Pagination
//
//##

$pagination-bg:                        $abn-contrast !default;
$pagination-active-color:              $abn-contrast !default;
$pagination-disabled-bg:               $abn-contrast !default;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             $gray-darkest !default;
$state-success-bg:               #f1f6e8 !default;
$state-success-border:           $brand-success !default;

$state-info-text:                $gray-dark !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              $brand-info !default;

$state-warning-text:             $gray-darkest !default;
$state-warning-bg:               $brand-warning !default;
$state-warning-border:           $brand-warning !default;

$state-danger-text:              $gray-darkest !default;
$state-danger-bg:                $brand-danger !default;
$state-danger-border:            $brand-danger !default;


//== i-texts
//
//##

//** i-text max width
$i-text-max-width:           200px !default;
//** i-text text color
$i-text-color:               $abn-contrast !default;
//** i-text background color
$i-text-bg:                  $gray-darkest !default;
$i-text-opacity:             .8 !default;

//** i-text arrow width
$i-text-arrow-width:         10px !default;
//** i-text arrow color
$i-text-arrow-color:         $i-text-bg !default;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          $abn-contrast !default;
//** Popover maximum width
$popover-max-width:                   350px !default;
//** Popover border color
$popover-border-color:                $gray-light !default;
//** Popover fallback border color
$popover-fallback-border-color:       $gray-light !default;


//** Popover arrow width
$popover-arrow-width:                 8px !default;
//** Popover arrow color
$popover-arrow-color:                 $abn-contrast !default;

//** Popover outer arrow width
$popover-arrow-outer-width:           (9px) !default;

$label-warning-bg:            $abn-attention !default;


$label-color:                 $abn-contrast !default;
//** Default text color of a linked label
$label-link-hover-color:      $abn-contrast !default;


//== Modals
//
//##


$modal-content-bg:                             $abn-contrast !default;

//== Progress bars
//
//##


$progress-bar-color:          $abn-contrast !default;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 $abn-contrast !default;

//== Panels
//
//##

$panel-bg:                    #f5f5f5 !default; // isn't there a var for this?
$panel-body-padding:          20px 10px !default;

$panel-default-text:          $abn-additional !default;
$panel-primary-text:          $abn-contrast !default;

$ocf-panel-title-medium:      24px !default;
$ocf-panel-title-small:       20px !default;


$ocf-panel-button-width-max:  185px !default;

//== Wells
//
//##

$well-bg:                     $abn-contrast !default;
$well-border:                 $abn-contrast !default;


//== Badges
//
//##

$badge-color:                 $abn-contrast !default;
//** Linked badge text color on hover
$badge-link-hover-color:      $abn-contrast !default;



//** Badge background color in active nav link
$badge-active-bg:             $abn-contrast !default;

$badge-font-weight:           bold !default;


// Cards
$card-spacer-x:            16px !default;
$card-spacer-y:            20px !default;
$card-border-width:        1px !default;
$card-border-radius:       $border-radius-base !default;
$card-border-color:        $gray-light !default;
$card-border-radius-inner: $card-border-radius !default;
$card-cap-bg:              $gray-lighter !default;
$card-bg:                  $abn-contrast !default;
$card-link-hover-color:    $abn-contrast !default;


//== Carousel
//
//##

$carousel-control-color:                      $abn-contrast !default;

$carousel-indicator-active-bg:                $abn-contrast !default;
$carousel-indicator-border-color:             $abn-contrast !default;

$carousel-caption-color:                      $abn-contrast !default;


//== Close
//
//##

$close-text-shadow:           0 1px 0 $abn-contrast !default;


//== Code
//
//##

$kbd-color:                   $abn-contrast !default;

//== Custom Variables
//
//##

//** Custom Variables will start here

// different sizes for icons to be used with custom classes
$icon-size-small:           16px !default;
$icon-size-default:         22px !default;
$icon-size-medium:          24px !default;
$icon-size-large :          30px !default;
$icon-size-extralarge:      62px !default;
$icon-size-extraextralarge: 75px !default;
$icon-size-logoservice:     46px !default;

// datepicker width
$datepicker-row-height:     26px;
$datepicker-width:          300px;

// small button height:
$btn-sm-height:             26px;
$btn-extraextralarge:       120px;


// jumbotron

$jumbotron-bg: $gray-lightest !default;

// social media icons

$ocf-socialmedia-bg:    #b6b6b6 !default;

$ocf-facebook:          #3B5998 !default;
$ocf-google:            #DF4A32 !default;
$ocf-instagram:         #3f729b !default;
$ocf-linkedin:          #0976B4 !default;
$ocf-rss:               #F39035 !default;
$ocf-twitter:           #55acee !default;
$ocf-youtube:           #E52D27 !default;

// gray branding (restricted use, allowed only in footer & in mobile menu in header)

$ocf-gray-branding:     #ECEBEC !default;

// curtain on content next to active mobile menu
$ocf-curtain:           rgba(0,0,0,.6) !default;
$menu-width-xs:         425px !default;
$menu-width-xxs:        265px !default;


// custom colors restricted for use in submenu
$ocf-gray-menu-lighter:     #dadada !default;
$gray-light-hover:          #c7c7c7 !default;

$ocf-gray-menu-light:       #c9c9c9 !default;
$ocf-gray-menu:             #d2d2d2 !default;

// double of $ocf-gray-menu-darker, check and cleanup:
$ocf-gray-menu-hover:       #b9b9b9 !default;


$ocf-gray-menu-dark:        #dbdbdb !default;
$ocf-gray-menu-darker:      #b9b9b9 !default;

$ocf-gray-menu-darkest:     #bebebe !default;

// variables for angularjs-slider, cannot reuse their code, because its written in LESS

$handle-active-color: $abn-additional !default;
$handle-hover-color: $abn-contrast !default;
$label-text-color: transparent !default;
$handle-bg-color: $abn-additional !default;
$handle-inner-color: $abn-contrast !default;
$handle-disabled-color: $gray-light !default;
$limit-label-text-color: $gray !default;
$bar-fill-color: $handle-bg-color !default;
$bar-normal-color: $gray-light !default;

$ticks-color: $bar-normal-color !default;
$selected-ticks-color: $bar-fill-color !default;
$ticks-width: 10px !default;
$ticks-height: 10px !default;
$ticks-value-position: -30px !default;
$ticks-legend-position: 24px !default;
$ticks-value-position-on-vertical: 22px !default;

$handle-size: 26px !default;
$handle-pointer-size: 24px !default;
$bubble-padding: 0 !default;
$bar-dimension: 10px !default;

$with-legend-margin: 40px !default;

// Spinner
$spinner-message-color: #c1c1c1 !default;
$spinner-backdrop-color: #ccc !default;