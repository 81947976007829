/**
 * ABN AMRO Header
 * Page layout, header
 * This part styles the header and its content
 */


// Header widget

header {
	margin-bottom: 20px;
}

.ocf-header {
    background: $abn-contrast;
    font-size: $font-size-alt;

    .container-fluid {

        &:before,
        &:after {
            content: '';
            display: none;
        }
    }

    &:after {
        clear: none;
    }

    @media (max-width: $screen-md-min - 1px) {
        text-align: left;
        height: 55px;
    }
}

.ocf-brand {
    height: 36px;
    width: 144px;
    display: inline-block;
    background: svg("ocf-logo-abnamro.svg") no-repeat top left;
    background-size: contain;
    margin: 18px 28px 8px 18px;

    @media (min-width: $screen-md-min) {
        margin-bottom: 5px;
    }

    @media (max-width: $screen-md-min - 1px) {
        background-position: 0 0;
        display: inline-block;
        height: 32px;
        margin: 0 0 9px 15px;
        position: absolute;
        top: 15px;
        left: 55px;
    }
    @media print {
        content: svg("ocf-logo-abnamro.svg");
        background: none;
    }
}


.ocf-brand.ocf-cutout:before {
    position: absolute;
    width: 32px;
    height: 22px;
    content: '';
    z-index: 10;
    background: svg("ocf-cutout") no-repeat top left;
    margin: 37px 0 0 -4px;

    @media (min-width: $screen-md-min) {
       margin: 44px 0 0 -1px;
    }
}

//Header without menu/navigation
.nav-off {
	.navbar,
    .navbar-container {
        display: none;
    }
}

@media print {
    .navbar,
    .navbar-container {
        display: none;
    }
}

.nav-off .ocf-brand {
    @media (max-width: $screen-md-min - 1px) {
        left: 0 !important;
        position: relative;
    }
}

.page-header {
    @media (max-width: $screen-md-min - 1px) {
        margin-top: 20px;
    }
}

.ocf-header-right {
    float: right;
    margin-top: 11px;

    @media (max-width: $screen-md-min - 1px) {
        margin-top: 10px;
        margin-right: 5px;
    }

    .ocf-header-search {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 6px 9px 0 22px;

        input {
            width: 250px;
            padding: 0 30px 2px 10px;
            border-top: 1px solid $gray;
            border-right: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            border-left: 1px solid $gray-light;
            line-height: 24px;
            height: 28px;
        }

        button {
            background: svg("ocf-icon-search.svg", ".primaryFill[fill]: #{$icon-action};") no-repeat 5px 5px;
            background-size: 18px;
            height: 28px;
            min-width: 30px;
            position: absolute;
            right: 0;
            text-indent: -9999em;
            top: 0;
            border: none;
            @media (max-width: $screen-sm-min - 1px) {
                width: 44px;
                height: 44px;
                background-position: 13px 13px;
                top: -8px;
            }
        }
    }

    .dropdown-menu {
        float: right;
        right: 0;
        left: inherit;
    }

    div,
    label {
        margin-left: 6px;
    }

    @extend .hidden-print;
}
