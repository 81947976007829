﻿// The footer navigation is made with Pills
// -------------------------
// 
footer .nav-pills {
	@media (max-width: $grid-float-breakpoint-max) {
		margin-top: 5px;
	}
	> ul {
		@media (max-width: $grid-float-breakpoint-max) {
			margin-top: 15px;
		}
	}

	> li {
		float: left;
		background-color: transparent;	

		@media (max-width: $grid-float-breakpoint-max) {
			font-size: $font-size-medium;


		}
		// Links rendered as pills
		> a {
			color: $abn-action;
			padding: 0 5px 0 0;
			display: inline-block;
            text-decoration: none;		

			&:hover,
			&:focus {
				color: $abn-primary-dark;
				background-color: transparent;	
                text-decoration: underline;
			}
		}
		+ li {
			margin-left: 0;
		}
		&:hover,
		&:focus {
			color: $abn-contrast;
			background-color: transparent;	
		}
	}
  }